import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isTabletOrMobile } from './if-mobile';

import agent from '../agent';
import Header from './Header';
import { APP_LOAD, REDIRECT, OPEN_MODAL, CLOSE_MODAL } from '../actionTypes';
import Editor from '../containers/Editor';
import Home from './Home';
import Login from './Login';
import Profile from './Profile';
import Register from './Register';
import Settings from './Settings';
import { store } from '../store';
import PreviewMobile from './Display/Preview';
import SelectPlan from './SelectPlan';
import PricingPage from './TestPlan';
import Published from './Display/Published';
import Commentary from '../containers/Commentary';
import Checkout from './Checkout';
import Modal from './Modal';
import Marketing from './Marketing';
import Contact from './Contact';

import { BABEL_DOMAINS } from '../constants';
import Registration from './Registration';
import Purchase from './Purchase';
// import Annotate from './Display/Annotate';
// import PublishedAnnotated from './Display/PublishedAnnotated';
import PublishedSimple from './Display/PublishedSimple'

const mapStateToProps = (state) => ({
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    modalIsActive: state.modal.isActive,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload, token) =>
        dispatch({
            type: APP_LOAD,
            payload,
            token,
            skipTracking: true,
        }),
    onRedirect: () => dispatch({ type: REDIRECT }),
    onOpenModal: () => dispatch({ type: OPEN_MODAL }),
    onCloseModal: () => dispatch({ type: CLOSE_MODAL }),
});

function App({
    redirectTo,
    appLoaded,
    appName,
    currentUser,
    onLoad,
    onRedirect,
    modalIsActive,
    onOpenModal,
    onCloseModal,
}) {

    useEffect(() => {
        const token = window.localStorage.getItem('jwt');
        if (token) {
            agent.setToken(token);
        }
        onLoad(token ? agent.Auth.current() : null, token);
    }, []);

    useEffect(() => {
        if (redirectTo) {
            store.dispatch(push(redirectTo));
            onRedirect();
        }
    }, [redirectTo]);

    function handleRootRouting(user) {
        const domain = window.location.hostname;
        if (BABEL_DOMAINS.includes(domain)) {
            return <Route path="/" component={Marketing} />
        } else if (user && user.token && user.customDomain === domain) {
            return <Route path="/" component={Home} />
        } else {
            return <Route path="/" component={Profile} />
        }
    }

    function handleProfileRouting(user) {
        const domain = window.location.hostname
        if (BABEL_DOMAINS.includes(domain)) {
            return <Route path="/@:username" component={Profile} />
        }
        return null
    }

    if (appLoaded) {
        return (
            <div>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                </Helmet>
                <Header appName={appName} currentUser={currentUser} onOpenModal={onOpenModal} />
                <Switch>
                    <Route exact path="/home" component={Home} />
                    <Route path="/login" component={Login} />
                    {/* <Route path="/register" component={Register} />
                    <Route path="/pricing" component={PricingPage} /> */}
                    <Route path="/editor/:slug" component={Editor} />
                    <Route path="/editor" component={Editor} />
                    <Route path="/preview" component={PreviewMobile} />
                    <Route path="/preview/:slug" component={PreviewMobile} />
                    {/* <Route path="/annotate/:slug" component={Annotate} /> */}
                    <Route path="/published/:slug" component={isTabletOrMobile() ? PublishedSimple : Published} />
                    {/* <Route path="/publishedannotated/:slug" component={PublishedAnnotated} /> */}
                    {/* <Route path="/published-mobile/:slug" component={PublishedSimple} /> */}
                    <Route path="/settings" component={Settings} />
                    <Route path="/" component={Profile} />
                    {/* {handleProfileRouting()}
                    <Route path="/commentary/:slug" component={Commentary} />
                    <Route path="/checkout" component={Checkout} />
                    <Route path="/marketing" component={Marketing} />
                    <Route path="/registration" component={Registration} />
                    <Route path="/purchase" component={Purchase} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/blog" component={Profile} />
                    <Route path="/blog/:slug" component={Published} /> */}
                    {/* {handleRootRouting(currentUser)} */}
                </Switch>
                <Modal isActive={modalIsActive} onClose={onCloseModal} register />
            </div>
        );
    }
    return (
        <div>
            <Header appName={appName} currentUser={currentUser} />
        </div>
    );
}

App.propTypes = {
    redirectTo: PropTypes.string,
    appLoaded: PropTypes.bool.isRequired,
    appName: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    onLoad: PropTypes.func.isRequired,
    onRedirect: PropTypes.func.isRequired,
    modalIsActive: PropTypes.bool,
    onOpenModal: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
