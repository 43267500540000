import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import agent from '../agent';
import { HOME_PAGE_LOADED, HOME_PAGE_UNLOADED, APPLY_TAG_FILTER } from '../actionTypes';
import { ReactComponent as PlusSign } from './Assets/plus-sign.svg';
import { IfNotMobile, isMobile } from './if-mobile';
import ArticlesAndTags from './ArticlesAndTags';

const { Promise } = global;

const mapStateToProps = (state) => ({
    ...state.home,
    appName: state.common.appName,
    token: state.common.token,
    username: state.common.currentUser && state.common.currentUser.username,
});

const mapDispatchToProps = (dispatch) => ({
    onClickTag: (tag, pager, payload) =>
        dispatch({
            type: APPLY_TAG_FILTER,
            tag,
            pager,
            payload,
        }),
    onLoad: (tab, pager, payload) =>
        dispatch({
            type: HOME_PAGE_LOADED,
            tab,
            pager,
            payload,
        }),
    onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
});

class Home extends React.Component {
    componentWillMount() {
        const tab = this.props.token ? 'your-articles' : 'all';
        const username = this.props.username || 'testinguser';

        // I think I can remove this once I have some kind of gating set up
        const articlesPromise = this.props.token
            ? agent.Articles.feed
            : agent.Articles.byAuthor.bind({ author: username });

        this.props.onLoad(
            tab,
            articlesPromise,
            Promise.all([agent.Tags.getTagsByAuthor(username), articlesPromise()]),
        );
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    render() {
        return (
            <div className="home-page">
                <div className="container page">
                    {/* <IfNotMobile> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className="get-started"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Link
                                        to="/editor"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '500px',
                                            paddingLeft: '30px',
                                            paddingRight: '30px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            backgroundColor: '#fff',
                                            borderColor: '#5485BB',
                                            borderWidth: 'thin',
                                            borderRadius: '1px',
                                            color: '#5485BB',
                                        }}
                                    >
                                        <PlusSign />
                                        <div className="writing-cta" style={{ paddingLeft: '1rem' }}>
                                            Get writing
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    {/* </IfNotMobile> */}
                    <ArticlesAndTags isProfile={false} username={this.props.username}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
