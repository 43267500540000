import { MOBILE_WIDTH, TABLET_WIDTH } from '../constants';

export default function IfMobile({ children }) {
    const shouldShow = window.innerWidth < MOBILE_WIDTH;
    if (!shouldShow) {
        return null;
    }
    return children;
}

export function IfNotMobile({ children }) {
    const shouldShow = window.innerWidth < MOBILE_WIDTH;

    if (shouldShow) {
        return null;
    }
    return children;
}

export function isMobile() {
    return window.innerWidth < MOBILE_WIDTH;
}

export function isTabletOrMobile() {
    return window.innerWidth < TABLET_WIDTH;
}

export function isTablet() {
    return window.innerWidth >= MOBILE_WIDTH && window.innerWidth < TABLET_WIDTH;
}
