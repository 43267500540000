import React from 'react';
import { connect } from 'react-redux';
import ArticleList from '../ArticleList';
import agent from '../../agent';
import { CHANGE_TAB } from '../../actionTypes';
import { DELETED_TAG, PUBLISHED_TAG } from '../../constants';

const YourArticlesTab = (props) => {

    const pager = props.isProfile ? agent.Articles.profileFeed.bind({author: props.username}) : agent.Articles.feed ;

    const clickHandler = (ev) => {
        ev.preventDefault();
        props.onTabClick('your-articles', pager, pager(props.username))
    };

    return (
        <li className="nav-item">
            <a
                href=""
                className={props.tab === 'your-articles' ? 'nav-link active' : 'nav-link'}
                onClick={clickHandler}
            >
                Your Articles
            </a>
        </li>
    );
};

const YourAnnotationsTab = (props) => {
    const clickHandler = (ev) => {
        ev.preventDefault();
        props.onTabClick('all', agent.Articles.byAuthor, agent.Articles.byAuthor('test'));
    };
    return (
        <li className="nav-item">
            <a href="" className={props.tab === 'all' ? 'nav-link active' : 'nav-link'} onClick={clickHandler}>
                Annotations
            </a>
        </li>
    );
};

const TagFilterTab = (props) => {
    if (!props.tag) {
        return null;
    }

    return (
        <li className="nav-item">
            <a href="" className="nav-link active">
                <i className="ion-pound" /> {props.tag}
            </a>
        </li>
    );
};

const mapStateToProps = (state) => ({
    ...state.articleList,
    tags: state.home.tags,
    token: state.common.token,
    username: state.common.currentUser && state.common.currentUser.username,
    annotations: [],
});

const mapDispatchToProps = (dispatch) => ({
    onTabClick: (tab, pager, payload) =>
        dispatch({
            type: CHANGE_TAB,
            tab,
            pager,
            payload,
        }),
});

const MainView = (props) => {

    const authorUsername = props.isProfile ? props.authorUsername : props.username 

    // Filtering on the client isn't good because 
    let filteredArticles = [];
    if (props.articles) {
        filteredArticles = props.isProfile 
            ? props.articles.filter(article => !article.tagList.includes(DELETED_TAG) && article.tagList.includes(PUBLISHED_TAG))
            : props.articles;
    }

    return (
        <div className="col-md-9">
            <div className="feed-toggle">
                <ul className="nav nav-pills outline-active">
                    <YourArticlesTab
                        token={props.token}
                        tab={props.tab}
                        onTabClick={props.onTabClick}
                        username={authorUsername}
                        isProfile={props.isProfile}
                    />

                    <YourAnnotationsTab tab={props.tab} onTabClick={props.onTabClick} />

                    <TagFilterTab tag={props.tag} />
                </ul>
            </div>

            <ArticleList
                pager={props.pager}
                articles={
                    props.tab === 'all'
                        ? props.annotations
                        : filteredArticles
                }
                tab={props.tab}
                loading={props.loading}
                articlesCount={filteredArticles.length}
                currentPage={props.currentPage}
                profile={props.isProfile}
            />
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
