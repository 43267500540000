import Hypher from 'hypher';

/**
 * Create a hyphenator that uses the given patterns.
 *
 * A wrapper around the `hypher` hyphenation library.
 */
export function createHyphenator(patterns) {
    const hypher = new Hypher(patterns);
    return (word) => hypher.hyphenate(word);
}
