import React from 'react';
import agent from '../../agent';

import { PUBLISHED_TAG } from '../../constants';

const Tags = (props) => {
    const { username, tags } = props;
    // Tags should be filtered by the user
    if (tags) {
        return (
            <div className="tag-list">
                {tags.map((tag) => {
                    const handleClick = (ev) => {
                        ev.preventDefault();
                        props.onClickTag(
                            tag,
                            (page) => agent.Articles.byAuthorAndTag(username, tag, page),
                            agent.Articles.byAuthorAndTag(username, tag),
                        );
                    };

                    return (
                        <a
                            href=""
                            className="tag-default tag-pill"
                            key={tag}
                            style={{ backgroudColor: '#5485BB' }}
                            onClick={handleClick}
                        >
                            {tag}
                        </a>
                    );
                })}
            </div>
        );
    }
    return <div>Loading Tags...</div>;
};

export default Tags;
