import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MainView from './MainView';
import Tags from './Tags';

import { isMobile } from '../if-mobile';
import { APPLY_TAG_FILTER } from '../../actionTypes';
import { DELETED_TAG } from '../../constants';
import agent from '../../agent';

const mapDispatchToProps = (dispatch) => ({
    onClickTag: (tag, pager, payload) =>
        dispatch({
            type: APPLY_TAG_FILTER,
            tag,
            pager,
            payload,
        }),
});

export function ArticlesAndTags({ username, isProfile, onClickTag }) {
    const [tags, setTags] = useState([]);

    useEffect(() => {
        // We need a get published tags by author
        async function fetchTags() {
            const loadedTags = isProfile
                ? await agent.Tags.getProfileTagsByAuthor(username)
                : await agent.Tags.getTagsByAuthor(username);
            setTags(loadedTags.tags);
        }
        fetchTags();
    }, []);

    return (
        <div>
            <div className={isMobile() ? 'row-mobile' : 'row'}>
                <MainView isProfile={isProfile} authorUsername={username} tab={'your-articles'} />

                <div className="col-md-3">
                    {isProfile && tags && (
                        <div className="sidebar" style={{ backgroundColor: '#fff', color: '#000' }}>
                            <p>Filter Tags</p>
                            <Tags
                                tags={tags && tags.filter((tag) => tag !== DELETED_TAG)}
                                onClickTag={onClickTag}
                                username={username}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

ArticlesAndTags.propTypes = {
    username: PropTypes.string,
    isProfile: PropTypes.bool,
    onClickTag: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ArticlesAndTags);
