import { PREVIEW_PAGE_LOADED, PREVIEW_PAGE_UNLOADED, ARTICLE_PUBLISHED, ASYNC_START } from '../actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case PREVIEW_PAGE_LOADED:
            return {
                ...state,
                articleSlug: action.payload ? action.payload.article.slug : window.location.pathname.split('/').pop(),
                title: action.payload ? action.payload.article.title : '',
                description: action.payload ? action.payload.article.description : '',
                body: action.payload ? action.payload.article.body : '',
                tagInput: '',
                tagList: action.payload ? action.payload.article.tagList : [],
                published: action.payload ? action.payload.article.published : false,
                authorUsername: action.payload ? action.payload.article.author.username : '',
                annotation: action.payload ? action.payload.article.annotation : false,
            };
        case PREVIEW_PAGE_UNLOADED:
            return {};
        case ASYNC_START:
            if (action.subtype === ARTICLE_PUBLISHED) {
                return { ...state, inProgress: true };
            }
            break;
    }

    return state;
};
