import {
    APP_LOAD,
    REDIRECT,
    LOGOUT,
    ARTICLE_SUBMITTED,
    SETTINGS_SAVED,
    LOGIN,
    REGISTER,
    DELETE_ARTICLE,
    ARTICLE_PAGE_UNLOADED,
    EDITOR_PAGE_UNLOADED,
    HOME_PAGE_UNLOADED,
    PROFILE_PAGE_UNLOADED,
    PROFILE_FAVORITES_PAGE_UNLOADED,
    SETTINGS_PAGE_UNLOADED,
    LOGIN_PAGE_UNLOADED,
    REGISTER_PAGE_UNLOADED,
    ARTICLE_PUBLISHED,
    PREVIEW_PAGE_UNLOADED,
    INITIALIZE_CHECKOUT,
    PAYMENT_SUBMITTED,
    ARTICLE_UNPUBLISHED,
} from '../actionTypes';

const defaultState = {
    appName: 'Babel',
    token: null,
    viewChangeCounter: 0,
};

export default (state = defaultState, action) => {
    let redirectUrl;
    switch (action.type) {
        case APP_LOAD:
            return {
                ...state,
                token: action.token || null,
                appLoaded: true,
                currentUser: action.payload ? action.payload.user : null,
            };
        case REDIRECT:
            redirectUrl = (action.payload && action.payload.redirectUrl) || null;
            return { ...state, redirectTo: redirectUrl };
        case LOGOUT:
            return {
                ...state,
                redirectTo: '/marketing',
                token: null,
                currentUser: null,
            };
        case ARTICLE_SUBMITTED:
            redirectUrl = `/preview/${action.payload.article.slug}`;
            return { ...state, redirectTo: redirectUrl, editorState: action.payload.editorState };
        case ARTICLE_PUBLISHED:
            redirectUrl = `/published/${action.payload.article.slug}`;
            return { ...state, redirectTo: redirectUrl, editorState: action.payload.editorState };
        case ARTICLE_UNPUBLISHED:
            redirectUrl = `/preview/${action.payload.article.slug}`;
            return { ...state, redirectTo: redirectUrl, editorState: action.payload.editorState };
        case SETTINGS_SAVED:
            return {
                ...state,
                redirectTo: action.error ? null : '/',
                currentUser: action.error ? null : action.payload.user,
            };
        case LOGIN:
            return {
                ...state,
                redirectTo: action.error ? null : '/home',
            }
        case REGISTER:
            return {
                ...state,
                redirectTo: action.error ? null : action.payload.redirectUrl,
                token: action.error ? null : action.payload.user.token,
                currentUser: action.error ? null : action.payload.user,
            };
        case PAYMENT_SUBMITTED:
            if (!state.token && !state.currentUser) {
                const redirectUrl = '/settings';
                return {
                    ...state,
                    token: action.error ? null : action.payload.user.token,
                    currentUser: action.error ? null : action.payload.user,
                    redirectTo: action.error ? null : redirectUrl,
                };
            }
            return {
                ...state,
            };
        case INITIALIZE_CHECKOUT:
            return {
                ...state,
                braintreeToken: action.payload,
            };
        case DELETE_ARTICLE:
            return { ...state, redirectTo: '/' };
        case ARTICLE_PAGE_UNLOADED:
        case EDITOR_PAGE_UNLOADED:
        case PREVIEW_PAGE_UNLOADED:
        case HOME_PAGE_UNLOADED:
        case PROFILE_PAGE_UNLOADED:
        case PROFILE_FAVORITES_PAGE_UNLOADED:
        case SETTINGS_PAGE_UNLOADED:
        case LOGIN_PAGE_UNLOADED:
        case REGISTER_PAGE_UNLOADED:
            return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
        default:
            return state;
    }
};
