import React, { useState } from 'react';

function FaqItem(props) {
    const [expanded, setExpanded] = useState(false);
  
    return (
      <article className="FaqItem py-4" onClick={() => setExpanded(!expanded)} style={{padding: '1em'}}>
        <h4>
          <span className="text-primary mr-3" style={{paddingRight: '1em'}}>
            <i
              className={
                "fas" +
                (expanded ? " fa-minus" : "") +
                (!expanded ? " fa-plus" : "")
              }
            />
          </span>
          {props.question}
        </h4>
  
        {expanded && <div className="mt-3">{props.answer}</div>}
      </article>
    );
  }

function Faq(props) {
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left', marginTop: '2em', textAlign: 'left'}}>
        {props.items.map((item, index) => (
          <FaqItem question={item.question} answer={item.answer} key={index} />
        ))}
      </div>
    );
  }

function FaqSection(props) {
  return (
      <div className="container">
        <Faq
          items={[
            {
              question: "What is Babel?",
              answer:
                "Babel is a writing platform for writers who care deeply about their craft. We want your work to look beautiful because we know you put everything you have into it.",
            },
            {
              question: "Is Babel free to use?",
              answer:
                "Yes! You can write and publish on Babel for free for as long as you want. If you ever want to upgrade.",
            },
            {
              question: "Is Babel an alternative to Medium and Substack?",
              answer:
                "Yes. Writing on Babel is professionally typeset unlike Medium or Substack. Medium and Substack allow you to collect subscribers + payments which Babel doesn't support yet (coming soon!).",
            },
            {
              question: "What comes in the Premium Plan?",
              answer:
                "With the Premium Plan you can bring your own custom domain (e.g. www.yourdomain.com). You can also replace Babel's logo with your own, and update the colors to be your own.",
            },
            {
              question: "What if I don't like it?",
              answer:
                "We do everything we can to make sure you're getting value out of Babel Writing. However if we're not able to meet your expectations you can cancel your plan anytime. Annual plans have a 14 day grace period where we'll give you a refund.",
            },
            {
              question: "How can I reach you?",
              answer:
                "You can reach us on Twitter (@babelwriting), on our contact page, or through our chat widget on the pricing page. We're always happy for feedback.",
            },
          ]}
        />
      </div>
  );
}

export default FaqSection;
