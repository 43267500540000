import { connect } from 'react-redux';
import {
    ADD_TAG,
    EDITOR_PAGE_LOADED,
    REMOVE_TAG,
    ARTICLE_SUBMITTED,
    EDITOR_PAGE_UNLOADED,
    UPDATE_FIELD_EDITOR,
} from '../actionTypes';
import Editor from '../components/Editor';

const mapStateToProps = (state) => ({
    ...state.editor,
    dirty: false,
    published: state.preview.published,
    editorSlug: state.editor && state.editor.articleSlug,
});

const mapDispatchToProps = (dispatch) => ({
    onAddTag: () => dispatch({ type: ADD_TAG }),
    onLoad: (payload) => dispatch({ type: EDITOR_PAGE_LOADED, payload }),
    onRemoveTag: (tag) => dispatch({ type: REMOVE_TAG, tag }),
    onSubmit: (payload) => dispatch({ type: ARTICLE_SUBMITTED, payload }),
    onUnload: (payload) => dispatch({ type: EDITOR_PAGE_UNLOADED }),
    onUpdateField: (key, value) => dispatch({ type: UPDATE_FIELD_EDITOR, key, value }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
