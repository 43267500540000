import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import agent from '../agent';
import { TAWK_TO_SCRIPT } from '../constants';
import ListErrors from './ListErrors';
import Modal from './Modal';
import {
    SETTINGS_SAVED,
    SETTINGS_PAGE_UNLOADED,
    LOGOUT,
    OPEN_CANCEL_MODAL,
    CLOSE_CANCEL_MODAL,
    SUBSCRIPTION_CANCELLED,
} from '../actionTypes';

async function uploadImages(url, user) {
    const uploadFormData = new FormData();
    uploadFormData.append('file', url);

    // eslint-disable-next-line no-await-in-loop
    const uploadRes = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/uploads?src=settings&username=${user.username}`, {
        method: 'POST',
        body: uploadFormData,
    });

    let uploadedUrl;
    // eslint-disable-next-line default-case
    switch (uploadRes.status) {
        case 200: // OK
            const { url } = await uploadRes.json();
            uploadedUrl = url;
            break;
        case 500: // INTERNAL_SERVER_ERROR
            throw new Error();
    }
    return uploadedUrl;
}

const SubscriptionsList = (props) => {
    const subscriptionUser = props.currentUser && props.currentUser.subscription;

    return (
        <div>
            {subscriptionUser ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        flexDirection: 'row',
                    }}
                >
                    <div> Premium plan</div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Link to="/pricing">
                            <button
                                className="btn btn-lg pull-xs-right btn-primary"
                                type="button"
                                style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                            >
                                Modify
                            </button>
                        </Link>
                        <div
                            className="btn btn-lg pull-xs-right btn-primary"
                            onClick={props.openModal}
                            type="button"
                            style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                        >
                            Cancel
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        flexDirection: 'row',
                    }}
                >
                    <div> Free plan </div>
                    <div>
                        <Link to="/pricing">
                            <button
                                className="btn btn-lg pull-xs-right btn-primary"
                                type="button"
                                style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                            >
                                Upgrade
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

class SettingsForm extends React.Component {
    constructor() {
        super();

        this.state = {
            image: '',
            username: '',
            bio: '',
            email: '',
            password: '',
            passwordConfirm: '',
            paswordsMatch: true,
            imageChanged: false,
        };

        this.updateState = (field) => (ev) => {
            const { state } = this;
            let passwordsMatch = true;

            let imageChanged = false;
            let image = this.state.image;
            if (field === 'image') {
                imageChanged = true;
                image = ev.target.files[0]
            }

            const newState = { ...state, [field]: ev.target.value, passwordsMatch, image, imageChanged };
            if (field.includes('password') && newState.password !== newState.passwordConfirm) {
                passwordsMatch = false;
            }
            this.setState(newState);
        };

        this.submitForm = async (ev) => {
            ev.preventDefault();

            // Upload the image if there is one.
            // Confirm new password and confirm password match.

            let image = this.props.currentUser.image;
            if (this.state.imageChanged) {
                image = await uploadImages(this.state.image, this.props.currentUser);
            }

            if (!this.state.passwordsMatch) {
                return <p>Passwords do not match</p>
            }

            const user = { ...this.state, image };
            if (!user.password) {
                delete user.password;
            }

            this.props.onSubmitForm(user);
        };
    }

    componentWillMount() {
        if (this.props.currentUser) {
            Object.assign(this.state, {
                //image: this.props.currentUser.image || '',
                username: this.props.currentUser.username,
                bio: this.props.currentUser.bio,
                email: this.props.currentUser.email,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentUser) {
            this.setState({
                ...this.state,
                //image: nextProps.currentUser.image || '',
                username: nextProps.currentUser.username,
                bio: nextProps.currentUser.bio,
                email: nextProps.currentUser.email,
            });
        }
    }

    render() {
        return (
            <form onSubmit={this.submitForm} encType="multipart/form-data">
                <fieldset>
                    <fieldset className="form-group">
                    {/* <button style={{display:'block', width: '120px', height: '30px'}} onClick={() => document.getElementById('getFile').click()} >Your text here</button>
                    <input type='file' id="getFile" style={{display:'none'}} /> */}
                        <label for="profilePic">Select Profile Image</label>
                        <input
                            id="profilePic"
                            className="form-control"
                            accept="image/*"
                            type="file"
                            placeholder="URL of profile picture"
                            onChange={this.updateState('image')}

                        />
                    </fieldset>

                    <fieldset className="form-group">
                        <label for="username">Username</label>
                        <input
                            id="username"
                            className="form-control form-control-lg"
                            type="text"
                            readOnly={true}
                            placeholder="Username"
                            value={this.state.username}
                            onChange={this.updateState('username')}
                        />
                    </fieldset>

                    <fieldset className="form-group">
                        <label for="bio">Profile Bio</label>
                        <textarea
                            id="bio"
                            className="form-control form-control-lg"
                            rows="8"
                            placeholder="Short bio about you shown on profile page"
                            value={this.state.bio}
                            onChange={this.updateState('bio')}
                        />
                    </fieldset>

                    <fieldset className="form-group">
                        <label for="email">Email address</label>
                        <input
                            id="email"
                            className="form-control form-control-lg"
                            type="email"
                            placeholder="Email"
                            readOnly={true}
                            value={this.state.email}
                            onChange={this.updateState('email')}
                        />
                    </fieldset>

                    <fieldset className="form-group">
                        <label for="password">Change Password</label>
                        <input
                            id="password"
                            className="form-control form-control-lg"
                            type="password"
                            minLength="8"
                            placeholder="New Password"
                            value={this.state.password}
                            onChange={this.updateState('password')}
                        />
                    </fieldset>
                    <fieldset className="form-group">
                        <label for="passwordConfirm">Confirm Password</label>
                        <input
                            id="passwordConfirm"
                            className="form-control form-control-lg"
                            type="password"
                            minLength="8"
                            placeholder="Confirm Password"
                            value={this.state.passwordConfirm}
                            onChange={this.updateState('passwordConfirm')}
                        />
                    </fieldset>

                    <button
                        className="btn btn-lg btn-primary pull-xs-right"
                        type="submit"
                        disabled={this.state.inProgress}
                    >
                        Update Settings
                    </button>
                </fieldset>
            </form>
        );
    }
}

class PremiumFeaturesForm extends React.Component {
    constructor() {
        super();

        this.state = {
            customDomain: '',
            logoImage: '',
            brandColor1: '',
            brandColor2: '',
            logoImageChanged: false,
        };

        this.updateState = (field) => (ev) => {
            const { state } = this;

            let logoImage = this.state.logoImage;
            let logoImageChanged = false;
            if (field === 'logoImage') {
                logoImageChanged = true;
                logoImage = ev.target.files[0]
            }

            const newState = { ...state, [field]: ev.target.value, logoImage, logoImageChanged };
            this.setState(newState);
        };

        this.submitForm = async (ev) => {
            ev.preventDefault();

            // Upload the logoImage if there is one.
            // Confirm new password and confirm password match.


            let logoImage = this.props.currentUser.logoImage;
            if (this.state.logoImageChanged) {
                logoImage = await uploadImages(this.state.logoImage, this.props.currentUser);
            }

            const user = { ...this.state, logoImage };
            if (!user.password) {
                delete user.password;
            }

            this.props.onSubmitForm(user);
        };
    }

    componentWillMount() {
        if (this.props.currentUser) {
            const user = this.props.currentUser;
            Object.assign(this.state, {
                customDomain: user.customDomain || '',
                logoImage: user.logoImage || '',
                brandColor1: (user.premiumFeatures && user.premiumFeatures.customDomain) || '',
                brandColor2: (user.premiumFeatures && user.premiumFeatures.customDomain) || '',
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentUser) {
            const user = nextProps.currentUser
            this.setState({
                ...this.state,
                customDomain: user.customDomain || '',
                logoImage: user.logoImage || '',
                brandColor1: (user.premiumFeatures && user.premiumFeatures.customDomain) || '',
                brandColor2: (user.premiumFeatures && user.premiumFeatures.customDomain) || '',
            });
        }
    }

    render() {
        return (
            <form onSubmit={this.submitForm} encType="multipart/form-data">
                <fieldset>
                    <fieldset className="form-group">
                        <label for="customDomain">Custom Domain - instructions here</label>
                        <input
                            id="customDomain"
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Enter custom domain URL"
                            value={this.state.customDomain}
                            onChange={this.updateState('customDomain')}
                        />
                    </fieldset>
                    <fieldset className="form-group">
                    {/* <button style={{display:'block', width: '120px', height: '30px'}} onClick={() => document.getElementById('getFile').click()} >Your text here</button>
                    <input type='file' id="getFile" style={{display:'none'}} /> */}
                        <label for="logoImage">Select Logo Image</label>
                        <input
                            id="logoImage"
                            className="form-control"
                            type="file"
                            placeholder="URL of page logo"
                            onChange={this.updateState('logoImage')}

                        />
                    </fieldset>

                    <button
                        className="btn btn-lg btn-primary pull-xs-right"
                        type="submit"
                        disabled={this.state.inProgress}
                    >
                        Update Premium Settings
                    </button>
                </fieldset>
            </form>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings,
    currentUser: state.common.currentUser,
    modalIsActive: state.modal.cancelModalIsActive,
});

const mapDispatchToProps = (dispatch) => ({
    onClickLogout: () => dispatch({ type: LOGOUT }),
    onSubmitForm: (user) => dispatch({ type: SETTINGS_SAVED, payload: agent.Auth.save(user) }),
    onUnload: () => dispatch({ type: SETTINGS_PAGE_UNLOADED }),
    onOpenModal: () => dispatch({ type: OPEN_CANCEL_MODAL }),
    onCloseModal: () => dispatch({ type: CLOSE_CANCEL_MODAL }),
    onSubmitModal: (payload) => dispatch({ type: SUBSCRIPTION_CANCELLED, payload }),
});

class Settings extends React.Component {
    render() {
        return (
            <div className="settings-page">
                <Helmet
                    script={[{
                        type: 'text/javascript',
                        innerHTML: TAWK_TO_SCRIPT,
                    }]} />
                <div className="container page">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 col-xs-12">
                            <h1 className="text-xs-center">Your Settings</h1>
                            <br />

                            <ListErrors errors={this.props.errors} />

                            <div style={{padding: '1em'}}>
                                <Link to={`/@${this.props.currentUser.username}`}>
                                    Click here to view your public profile
                                </Link>
                            </div>

                            <SettingsForm currentUser={this.props.currentUser} onSubmitForm={this.props.onSubmitForm} />

                            <hr />

                            {(this.props.currentUser && this.props.currentUser.subscription) && (
                                <h2 className="text-xs-center">Premium features</h2>
                            )}

                            {(this.props.currentUser && this.props.currentUser.subscription) && (
                                <div> 
                                    <PremiumFeaturesForm currentUser={this.props.currentUser} onSubmitForm={this.props.onSubmitForm} />
                                </div>
                            )}

                            <hr />

                            <h2 className="text-xs-center">Your subscriptions</h2>
                            <SubscriptionsList
                                currentUser={this.props.currentUser}
                                openModal={this.props.onOpenModal}
                            />

                            <hr />

                            <button className="btn btn-outline-danger" onClick={this.props.onClickLogout}>
                                Or click here to logout.
                            </button>
                            <Modal
                                isActive={this.props.modalIsActive}
                                onClose={this.props.onCloseModal}
                                register={false}
                                user={this.props.currentUser}
                                onSubmitModal={this.props.onSubmitModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
