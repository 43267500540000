import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';

import { isMobile } from './if-mobile';
import { REDIRECT, REGISTER } from '../actionTypes';
import agent from '../agent';
import FaqSection from './Faq';
import AppFooter from './Footer';

const buttonClasses = isMobile() ? "btn btn-lg btn-primary" : "btn btn-lg pull-xs-left btn-primary";

const fullSizeDivStyle =  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}

const mobileDivStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const largeStyles = {
    cta: { padding: '1em', display: 'flex', alignItems: 'center', marginTop: '2em' },
    topSection: { minWidth: '60%', textAlign: 'left' },
    divStyle: fullSizeDivStyle,
    image: { width: '45%', padding: '1em' },
    sectionMargin: { marginTop: '2em', padding: '1em'},
    ourFocus: { ...fullSizeDivStyle, flexDirection: 'row' },
    container: {paddingBottom: '4em', marginTop: '4em'},
    howItWorks: { padding: '1em', display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '13pt'},
    howItWorksReverse: { padding: '1em', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', fontSize: '13pt'},
    howItWorksContent: {
        textContainer: { width: '60%'},
        text: { padding: '1em', textAlign: 'left', width: '80%'},
        // imageContainer: { width: '40%'},
        image: { width : '100%' }
    },
    howItWorksReverseContent: {
        textContainer: { width: '40%'},
        text: { padding: '1em', textAlign: 'left', width: '100%'},
        imageContainer: { width: '60%'},
        image: { width : '80%' }
    }
}

const mobileStyles = {
    cta: { padding: '1em', display: 'flex', flexDirection: 'column-reverse', alignItems: 'center' },
    topSection: { padding: '1em' },
    divStyle: mobileDivStyle,
    image: { width: '100%', paddingTop: '1em' },
    sectionMargin: { marginTop: '1em', padding: '1em'},
    ourFocus: { ...mobileDivStyle, flexDirection: 'column-reverse' },
    container: { textAlign: 'center'},
    howItWorks: { padding: '1em', display: 'flex', flexDirection: 'column-reverse', alignItems: 'center', justifyContent: 'center', fontSize: '13pt', marginTop: '1em'},
    howItWorksReverse: { padding: '1em', display: 'flex', flexDirection: 'column-reverse', alignItems: 'center', fontSize: '13pt', marginTop: '1em'},
    howItWorksContent: {
        textContainer: { width: '100%', marginTop: '2em'},
        text: { padding: '1em', textAlign: 'left', width: '100%'},
        imageContainer: { width: '100%',},
        image: { width : '100%' }
    },
    howItWorksReverseContent: {
        textContainer: { width: '100%', },
        text: { padding: '1em', textAlign: 'left', width: '100%'},
        imageContainer: { width: '100%'},
        image: { width : '100%' }
    }
}

const testimonialStyles = {
    borderRadius: '100%',
    maxWidth: '50px',

}

const testimonialMargins = {
    margin: '1em',
    width: '20em'
}

const styles = isMobile() ? mobileStyles : largeStyles;

// At 1000 width the image starts to shrink but I could line break on beautiful

const mapStateToProps = (state) => ({
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
})

function Marketing(props) {
    const dispatch = useDispatch()

    async function getStarted() {
        if (!props.currentUser) {
            const username = `babeltempuser${nanoid(12)}`;
            const password = 'random-password';
            const email = `${username}@babeltemp.com`;
            const payload = await agent.Auth.register(username, email, password);
            dispatch({ type: REGISTER, payload });
            dispatch({ type: REDIRECT, payload: {redirectUrl: '/editor' }})

        } else {
            dispatch({ type: REDIRECT, payload: {redirectUrl: '/editor' }})
        }
    }

    return (
        <div className="marketing-page" style={{ }}>
            <div className="container" style={styles.container}>
                <div id="cta" style={styles.cta}>
                    <div id="top-section" style={styles.topSection}>
                        <h1>Get feedback while you write</h1>
                        <div style={{ marginTop: '1em', textAlign: 'left', width: '80%' }}>
                            <p>You control the quality of your writing. Feedback can help improve this.</p>
                            <p>Many writers acknowledge that early and frequent feedback on their work improves the quality of their writing.</p>
                            <p>Feedback can help motivate writers and helps you in your consistency, something widely seen as one of the hardest parts of writing.</p>
                        </div>
                        {/* <Link to={`/register`} style={{ backgroundColor: '#ffffff', border: '0' }} title="Register"> */}
                            <button
                                className={buttonClasses}
                                type="button"
                                style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '0.5em' }}
                                onClick={() => getStarted()}
                            >
                                Start writing
                            </button>
                        {/* </Link> */}
                    </div>
                    <div style={styles.howItWorksContent.imageContainer}>
                        <figure style={{width: '100%', margin: 0}}>
                        <img
                            // src={'https://cdn.trollcleaner.com/darwin-vegher-W_ZYCEUapF0-unsplash.jpg'}
                            //src={'https://cdn.trollcleaner.com/text2.png'}
                            src={'https://cdn.trollcleaner.com/babel_writing_example.jpg'}
                            style={{ ...styles.howItWorksContent.image, padding: '1em', width: '100%', backgroundColor: '#f2eecb', borderRadius: '20px' }}
                        />
                            <figcaption style={{textAlign: 'center'}}>Feedback with Babel</figcaption>

                        </figure>
                        {/* <figure style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <img
                                src={'https://cdn.trollcleaner.com/babel_writing_example.png'}
                                style={{ width: '80%', padding: '1em' }}
                            />
                            <figcaption>Made with Babel</figcaption>
                        </figure> */}
                    </div>
                </div>
                <div id="mid-section" style={styles.sectionMargin}>
                    <div
                        style={{
                            ...styles.divStyle,
                            flexDirection: 'column',
                        }}
                    >
                        <h2>Babel Writing Inspiration</h2>
                        <div 
                            style={styles.divStyle}
                        >
                            <img
                                src={'https://cdn.trollcleaner.com/darwin-vegher-W_ZYCEUapF0-unsplash-min.jpg'}
                                style={styles.image}
                            />
                            <img
                                src={'https://cdn.trollcleaner.com/john-mark-smith-GK-HeykwfjQ-unsplash-min.jpg'}
                                style={styles.image}
                            />
                            <img
                                src={'https://cdn.trollcleaner.com/book3-min-2.jpg'}
                                style={styles.image}
                            />
                        </div>
                    </div>
                </div>
                <div id="section-3" style={styles.sectionMargin}>
                    <h2>Our focus is your writing</h2>

                    <div style={{...styles.ourFocus, }}>
                        <div>
                            <div style={{ padding: '1em' }}>
                                <h3>Aesthetics matter</h3>
                                <p>{'We use professional typesetting so your writing stands out. Our committment is that your work will look better on Babel Writing than on any other writing app.'}</p>
                            </div>
                            <div style={{ padding: '1em' }}>
                                <h3>Content matters</h3>
                                <p>{'Your readers are intelligent, write dense meaningful work for them. with the help of margin comments. Let your readers get deep into your work'}</p>
                            </div>
                            <div style={{ padding: '1em' }}>
                                <h3>Write and publish for free</h3>
                                <p>{'We only charge for premium features. Upgrade when you want to start building a brand or monetizing your writing.'}</p>
                            </div>
                            <button
                                className={buttonClasses}
                                type="button"
                                style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '0.5em' }}
                                onClick={() => getStarted()}
                            >
                                Start writing
                            </button>
                        </div>
                        <div>
                            <figure style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <img
                                    src={'https://cdn.trollcleaner.com/babel_writing_example.jpg'}
                                    style={{ width: '80%', padding: '1em' }}
                                />
                                <figcaption>Made with Babel</figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
                {/* <div id="section-4" style={styles.sectionMargin}>
                    <div
                        style={{
                            ...styles.divStyle,
                            padding: '1em',
                        }}
                    >
                    </div>
                </div> */}
                <div id="section-5" style={styles.sectionMargin}>
                    <div
                        style={{
                            ...styles.divStyle,
                            flexDirection: 'column',
                        }}
                    >
                        <h2>What people are saying</h2>
                        <div 
                            style={{...styles.divStyle, marginTop: '2em'}}
                        >
                            <div style={testimonialMargins}>
                                <div><p>{'Love that I can finally have professional typesetting in a free to use app.'}</p></div>
                                <div style={{...styles.divStyle, alignItems: 'center', justifyContent: 'left'}}>
                                    <img 
                                        src={'https://cdn.trollcleaner.com/testimonial3.jpeg'} 
                                        style={testimonialStyles}
                                    />
                                    <div style={{margin: '1em'}}>A. D'Souza</div>
                                </div> 
                            </div>
                            <div style={testimonialMargins}>
                                <div><p>{'I love reading blogs written on Babel. It\'s the best.'}</p></div>
                                <div style={{...styles.divStyle, alignItems: 'center', justifyContent: 'left'}}>
                                    <img 
                                        src={'https://cdn.trollcleaner.com/testimonial1.jpeg'} 
                                        style={testimonialStyles}
                                    />
                                    <div style={{margin: '1em'}}>E. McKenzie</div>
                                </div> 
                            </div>
                            <div style={testimonialMargins}>
                                <div><p>{'Beautiful look and style like LaTeX, but as easy to use as Medium or Substack.'}</p></div>
                                <div style={{...styles.divStyle, alignItems: 'center', justifyContent: 'left'}}>
                                    <img 
                                        src={'https://cdn.trollcleaner.com/testimonial2.jpeg'} 
                                        style={testimonialStyles}
                                    />
                                    <div style={{margin: '1em'}}>R. Fiero</div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div id="section-6" style={styles.sectionMargin}>
                    <h2>How it works</h2>
                    <div style={{...styles.ourFocus, flexDirection: 'column', alignItems: 'left', marginTop: '1em', textAlign: 'left' }}>
                        <div style={styles.howItWorks}>
                            <div style={styles.howItWorksContent.textContainer}>
                                <h3>1. Try it out</h3> 
                                <div style={styles.howItWorksContent.text}>
                                    <p>No sign up required. Write as much as you want and preview how it will look to your audience. We want you to be satisfied with Babel before signing up.</p>
                                </div>
                                <button
                                    className={buttonClasses}
                                    type="button"
                                    style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '0.5em' }}
                                    onClick={() => getStarted()}
                                >
                                    Start Writing
                                </button>
                            </div>
                            <div style={styles.howItWorksContent.imageContainer}>
                                <img src='https://cdn.trollcleaner.com/undraw_typewriter_i8xd.svg' style={styles.howItWorksContent.image}/>
                            </div>
                        </div>
                        <div style={styles.howItWorksReverse}>
                        <div style={styles.howItWorksReverseContent.textContainer}>
                                <h3>2. Publish</h3>
                                <div style={styles.howItWorksReverseContent.text}>
                                    <p>When you're happy with your writing and how it looks, sign up for a free account to publish unlimited writing.</p>
                                </div>
                                <button
                                    className={buttonClasses}
                                    type="button"
                                    style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '0.5em' }}
                                    onClick={() => getStarted()}
                                >
                                    Start Writing
                                </button>
                            </div>
                            <div style={styles.howItWorksReverseContent.imageContainer}>
                                <img src='https://cdn.trollcleaner.com/undraw_online_reading_np7n-2.svg' style={styles.howItWorksReverseContent.image}/>
                            </div>
                        </div>
                        <div style={styles.howItWorks}>
                            <div style={styles.howItWorksContent.textContainer}>
                                <h3>3. Upgrade (if you want)</h3>
                                <div style={styles.howItWorksContent.text}>
                                    <p>Stand out from the crowd and build your own brand. Upgrade to a premium account when you're ready. Bring your own domain and branding to grow your own personal audience.</p>
                                </div>
                                <button
                                    className={buttonClasses}
                                    type="button"
                                    style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '0.5em' }}
                                    onClick={() => getStarted()}
                                >
                                    Start Writing
                                </button>
                            </div>
                            <div style={styles.howItWorksContent.imageContainer}>
                                <img src='https://cdn.trollcleaner.com/undraw_stand_out_1oag-1.svg' style={styles.howItWorksContent.image}/>
                            </div>
                        </div>
                    </div>
                </div> 
                <div id="section-7" style={{...styles.sectionMargin}}>
                    <h2>Frequently Asked Questions</h2>
                    <FaqSection 
                        bg="white"
                        textColor="dark"
                        size="md"
                        bgImage=""
                        bgImageOpacity={1}
                        title="Frequently Asked Questions"
                        subtitle=""
                    />
                    <button
                        className={buttonClasses}
                        type="button"
                        style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '0.5em' }}
                        onClick={() => getStarted()}
                    >
                        Start Writing
                    </button>
                </div>
                <div id="section-8" style={{...styles.sectionMargin, marginBottom: '18em' }}>
                    <div style={{  ...styles.divStyle, flexDirection: 'column',}}>
                        <h2>Stay in touch</h2>
                        <div style={{...styles.ourFocus, flexDirection: 'column', marginTop: '2em'}}>
                            <p>No spam. Just beautiful writing inspiration and news about Babel Writing.</p>
                            <form
                                action="https://formspree.io/f/mdopydba"
                                method="POST"
                            >

                                <input type="text" label="email" placeholder="enter email..." name="_replyto"/>


                                <button 
                                    className="btn btn-lg btn-primary"
                                    type="button"
                                    style={{ borderRadius: '2rem', fontSize: '1rem', marginLeft: '1em' }}
                                    type="submit">
                                        Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div id="section-8" style={{ 
                    position: 'absolute',
                    marginTop: '2em',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                    }}
                >
                    {footerCode()}
                </div> */}

            </div>
            <AppFooter />
        </div>
        
    );
}

export default connect(mapStateToProps)(Marketing);
