import React, { useEffect, useState, useCallback } from 'react';
import enUsPatterns from 'hyphenation.en-us';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

import agent from '../../agent';
import { PUBLISHED_TAG, MOBILE_WIDTH } from '../../constants';
import { justifyContent, createHyphenator } from '../../lib/linebreak';
import { generateLayoutSample } from '../../lib/content/convert-editor-to-preview';

import { ReactComponent as BackwardArrow } from '../Assets/backward-arrow.svg';
import { ReactComponent as ForwardArrow } from '../Assets/forward-arrow.svg';
import Modal from '../Modal';
import { isMobile } from '../if-mobile';
import { PREVIEW_PAGE_LOADED,
    ARTICLE_PUBLISHED, 
    ARTICLE_UNPUBLISHED, 
    OPEN_CANCEL_MODAL, 
    CLOSE_CANCEL_MODAL, 
    SUBSCRIPTION_CANCELLED, 
    PREVIEW_PAGE_UNLOADED 
} from '../../actionTypes';
import { styles, mobileStyles } from './styles';

const mapStateToProps = (state) => ({
    articleSlug: state.preview.articleSlug || window.location.pathname.split('/').pop(),
    articleBody: state.preview.body,
    currentUsername: state.common.currentUser ? state.common.currentUser.username : null,
    authorUsername: state.preview.authorUsername,
    title: state.preview.title,
    tagList: state.preview.tagList,
    published: state.preview.published,
    currentUser: state.common.currentUser,
    modalIsActive: state.modal.cancelModalIsActive,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload) => dispatch({ type: PREVIEW_PAGE_LOADED, payload }), // This needs to switch over to editor page.
    onSubmit: (payload) => dispatch({ type: ARTICLE_PUBLISHED, payload }),
    onSubmitUnPublish: (payload) => dispatch({ type: ARTICLE_UNPUBLISHED, payload }),
    onOpenModal: () => dispatch({ type: OPEN_CANCEL_MODAL }),
    onCloseModal: () => dispatch({ type: CLOSE_CANCEL_MODAL }),
    onSubmitModal: (payload) => dispatch({ type: SUBSCRIPTION_CANCELLED, payload }),
    onUnload: () => dispatch({ type: PREVIEW_PAGE_UNLOADED }),
});

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function Preview({
    articleSlug,
    articleBody,
    title,
    currentUsername,
    currentUser,
    authorUsername,
    inProgress,
    published,
    tagList,
    onLoad,
    onUnload,
    onSubmit,
    onSubmitUnPublish,
    submitForm,
    onOpenModal,
    onCloseModal,
    onSubmitModal,
    modalIsActive,
}) {
    const [layout, setLayout] = useState();
    const [isPublished, setIsPublished] = useState(published);
    const bodyStyle = window.innerWidth < MOBILE_WIDTH ? mobileStyles : styles;
    const isAuthor = currentUsername === authorUsername;

    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState('signup');

    const [hasMargins, setHasMargins] = useState(false);

    const codeRef = useCallback((node) => {
        if (node !== null) {
            highlight(node);
        }
    }, []);

    useEffect(() => {
        const slug = articleSlug || window.location.pathname.split('/').pop();
        document.addEventListener('touchstart', function (e) {
            e.preventDefault();
        });
        if (slug) {
            onLoad(agent.Articles.get(slug));
        } else {
            this.props.onLoad(null);
        }
        return () => { onUnload() };
    }, []);

    useEffect(() => {
        // Need to adjust for horizontal view
        if (isMobile()) {
            if (window.innerHeight > window.innerWidth) {
                const halfway = -10 + (5 * window.innerWidth) / 16;
                //window.scrollTo(halfway, 0);
            }
        }
        if (articleBody) {
            const parsedLayout = generateLayoutSample(JSON.parse(articleBody));
            setLayout(parsedLayout);
        }
    }, [articleBody]);

    useEffect(() => {
        if (layout) {
            const hyphenate = createHyphenator(enUsPatterns);
            const htmlParas = Array.from(document.querySelectorAll('.auto-justify'));

            async function waitForFontsToLoadAndJustify() {
                let status = document.fonts.status;
                let count = 0;
                while (status === 'loading' && count < 20) {
                    await timeout(500);
                    count += 1;
                    status = document.fonts.status;
                }
                justifyContent(htmlParas, hyphenate)
                setHasMargins(layout.leftMargin.length > 0 || layout.rightMargin.length > 0);
            }

            waitForFontsToLoadAndJustify()

        }
        highlight();
    }, [layout]);

    useEffect(() => {
        if (tagList && tagList.includes(PUBLISHED_TAG)) {
            setIsPublished(true)
        } else {
            setIsPublished(false)
        }
        
    }, [tagList])

    function publishArticle() {
        const slug = { slug: articleSlug };
        const article = { published: true, tagList: tagList.concat(PUBLISHED_TAG) };
        setIsPublished(true);
        const promise = agent.Articles.update(Object.assign(article, slug));
        onSubmit(promise);
    }

    function unPublishArticle() {
        const slug = { slug: articleSlug };
        const filteredTags = tagList && tagList.filter((tag) => tag != PUBLISHED_TAG);
        const article = { published: false, tagList: filteredTags };
        setIsPublished(false);
        const promise = agent.Articles.update(Object.assign(article, slug));
        onSubmitUnPublish(promise);
    }

    function highlight(node) {
        if (node) {
            const pre = node.querySelectorAll('pre');
            if (pre) {
                pre.forEach((n) => {
                    hljs.highlightBlock(n);
                });
            }
        }
    }

    return (
        <div>
            {isAuthor && (
                <div
                    className="preview-btn"
                    style={{
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Link
                        to={`/editor/${articleSlug}`}
                        style={{ backgroundColor: '#ffffff', border: '0' }}
                        title="Go back to editor"
                    >
                        <BackwardArrow />
                    </Link>
                    {currentUsername.includes('babeltempuser') ? ( 
                        <a href="/select" target="_blank">
                            <div onClick={onOpenModal} title="Publish work" style={{ cursor: 'pointer' }}>
                                <ForwardArrow />
                            </div>
                        </a>
                    ) : (
                        <div onClick={publishArticle} title="Publish work" style={{ cursor: 'pointer' }}>
                            <ForwardArrow />
                        </div>
                    )}
                </div>
            )}
            <div id="content-body" style={bodyStyle.contentBody}>
                {title && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {!isMobile() && <div key={`lm-0`} style={{...bodyStyle.marginStyles(0), paddingTop: '0'}}>
                                <p id={'lm-0'}>&nbsp;</p>
                            </div>}
                        <div id="titleBlock" style={bodyStyle.title}>
                            <p>{title}</p>
                        </div>
                        {!isMobile() && <div key={`rm-0`} style={{...bodyStyle.marginStyles(0), paddingTop: '0'}}>
                            <p id={'rm-0'}>&nbsp;</p>
                        </div>}
                    </div>
                )}
                {layout && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div> 
                            {isMobile() && layout.leftMargin.map((block, index) => (
                                <div key={`lm-${index}`} style={{
                                    padding: '0px',
                                    margin: '0px',
                                    paddingTop: `${5 + block.y / 50}em`,
                                    fontSize: '32pt',
                                }} onClick={() => {
                                    setModalType('comment');
                                    setModalContent(block.text);
                                    onOpenModal();
                                }}>❤️</div>
                            ))}
                        </div>
                        {!isMobile() && <div id="left-margins" style={bodyStyle.leftMargin}>
                            {layout.leftMargin.map((block, index) => (
                                <div key={`lm-${index}`} style={bodyStyle.marginStyles(block.y)}>
                                    <p id={block.i} dangerouslySetInnerHTML={{ __html: block.text }}></p>
                                </div>
                            ))}
                            {layout.leftMargin.length === 0 && hasMargins && (
                                <div key={`lm-0`} style={bodyStyle.marginStyles(0)}>
                                    <p id={'lm-0'}>&nbsp;</p>
                                </div>
                            )}
                        </div>}
                        <div id="col1" style={bodyStyle.body}>
                            {layout.body.map((block, index) => {
                                if (block.image) {
                                    return (
                                        <figure
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                        >
                                            <img
                                                key={`r-${index}`}
                                                className="test"
                                                style={bodyStyle.image}
                                                src={block.url}
                                                alt="test"
                                            />
                                            <figcaption style={{...bodyStyle.image, padding: '1em'}} dangerouslySetInnerHTML={{ __html: block.text }}></figcaption>
                                        </figure>
                                    );
                                } else if (block.code) {
                                    return (
                                        <div
                                            key={`l-${index}`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <p
                                                id={block.i}
                                                style={{ maxWidth: '600px', minWidth: '600px' }}
                                                className={'auto-justifyx output-px'}
                                                dangerouslySetInnerHTML={{ __html: block.text }}
                                                ref={codeRef}
                                            >
                                                {/* {block.text} */}
                                            </p>
                                        </div>
                                    );
                                } else if (block.blockQuote) {
                                    return (
                                        <div
                                            key={`l-${index}`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <blockquote
                                                id={block.i}
                                                style={{
                                                    maxWidth: '600px',
                                                    minWidth: '600px',
                                                    fontSize: '18pt',
                                                    padding: '0.5em',
                                                    borderLeft: '2px solid #000',
                                                }}
                                                className={'auto-justify output-p'}
                                                dangerouslySetInnerHTML={{ __html: block.text }}
                                                ref={codeRef}
                                            >
                                                {/* {block.text} */}
                                            </blockquote>
                                        </div>
                                    );
                                }
                                return (
                                    <div key={index}>
                                        <p
                                            id={block.i}
                                            className={'auto-justify output-p'}
                                            dangerouslySetInnerHTML={{ __html: block.text }}
                                        >
                                            {/* {block.text} */}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div> 
                            {isMobile() && layout.rightMargin.map((block, index) => (
                                <div key={`lm-${index}`} style={{
                                    padding: '0px', 
                                    margin: '0px', 
                                    paddingTop: `${5 + block.y / 60}em`,
                                    fontSize: '32pt',
                                }} onClick={() => {
                                    setModalType('comment');
                                    setModalContent(block.text);
                                    onOpenModal();
                                }}>❤️</div>
                            ))}
                        </div>
                        {!isMobile() && <div id="right-margins" style={bodyStyle.rightMargin}>
                            {layout.rightMargin.map((block, index) => (
                                <div key={`rm-${index}`} style={bodyStyle.marginStyles(block.y)}>
                                     <p id={block.i} dangerouslySetInnerHTML={{ __html: block.text }}></p>
                                </div>
                            ))}
                            {layout.rightMargin.length === 0 && hasMargins && (
                                <div key={`rm-0`} style={bodyStyle.marginStyles(0)}>
                                    <p id={'rm-0'}>&nbsp;</p>
                                </div>
                            )}
                        </div>}
                    </div>
                )}
                {isAuthor && (
                    <div
                        className="end-buttons"
                        style={{
                            display: 'flex',
                            padding: '10px',
                            flexDirection: 'row',
                            width: isMobile() ? '300px' : '600px',
                            justifyContent: 'start',
                        }}
                    >
                        <Link
                            to={`/editor/${articleSlug}`}
                            style={{ backgroundColor: '#ffffff', border: '0' }}
                            title="Go back to editor"
                        >
                            <button
                                className="btn btn-lg pull-xs-right btn-primary"
                                type="button"
                                style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                                disabled={inProgress}
                                onClick={submitForm}
                            >
                                Back to editor
                            </button>
                        </Link>
                        {currentUsername.includes('babeltempuser') ? ( 
                            // <a href="/pricing" target="_blank">
                                <button
                                    className="btn btn-lg pull-xs-right btn-primary"
                                    type="button"
                                    style={{ borderRadius: '2rem', fontSize: '1rem' }}
                                    disabled={inProgress}
                                    onClick={onOpenModal}
                                >
                                    {isPublished ? 'Unpublish work' : 'Publish work'}
                                </button>
                            // </a>
                        ) : (
                            <button
                                className="btn btn-lg pull-xs-right btn-primary"
                                type="button"
                                style={{ borderRadius: '2rem', fontSize: '1rem' }}
                                disabled={inProgress}
                                onClick={isPublished ? unPublishArticle : publishArticle}
                            >
                                {isPublished ? 'Unpublish work' : 'Publish work'}
                            </button>
                        )}
                    <Modal
                        isActive={modalIsActive}
                        onClose={onCloseModal}
                        modalType={modalType}
                        user={currentUser}
                        onSubmitModal={onSubmitModal}
                        content={modalContent}
                    />
                    </div>
                )}
            </div>
        </div>
    );
}

Preview.propTypes = {
    articleSlug: PropTypes.string,
    articleBody: PropTypes.string,
    title: PropTypes.string,
    inProgress: PropTypes.bool,
    published: PropTypes.bool,
    tagList: PropTypes.array,
    currentUsername: PropTypes.string,
    authorUsername: PropTypes.string,
    onLoad: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitUnPublish: PropTypes.func,
    submitForm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
