import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_SERVER_ADDRESS;

const encode = encodeURIComponent;
const responseBody = (res) => res.body;

let token = null;
const tokenPlugin = (req) => {
    if (token) {
        req.set('authorization', `Token ${token}`);
    }
};

const requests = {
    del: (url) => superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
    get: (url) => superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
    put: (url, body) => superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
    post: (url, body) => superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
};

const Stripe = {
    create: (priceId) =>
      requests.post('/stripe', { priceId: priceId }),
    billing: () =>
      requests.post('/stripe/billing')
  }

const Auth = {
    current: () => requests.get('/user'),
    login: (email, password) => requests.post('/users/login', { user: { email, password } }),
    register: (username, email, password) => requests.post('/users', { user: { username, email, password } }),
    save: (user) => requests.put('/user', { user }),
};

const Tags = {
    getAll: () => requests.get('/tags'),
    getTagsByAuthor: (author) => requests.get(`/tags?author=${encode(author)}`),
    getProfileTagsByAuthor: (author) => requests.get(`/tags?author=${encode(author)}&profile=true`)
};

const ARTICLE_COUNT = 50;
const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = (article) => ({ ...article, slug: undefined });

const Articles = {
    all: (page) => requests.get(`/articles?${limit(ARTICLE_COUNT, page)}`),
    byAuthor: (author, page) => requests.get(`/articles?author=${encode(author)}&${limit(ARTICLE_COUNT, page)}`),
    byTag: (tag, page) => requests.get(`/articles?tag=${encode(tag)}&${limit(ARTICLE_COUNT, page)}`),
    byAuthorAndTag: (author, tag, page) =>
        requests.get(`/articles?author=${encode(author)}&tag=${encode(tag)}&${limit(ARTICLE_COUNT, page)}`),
    del: (slug) => requests.del(`/articles/${slug}`),
    favorite: (slug) => requests.post(`/articles/${slug}/favorite`),
    favoritedBy: (author, page) => requests.get(`/articles?favorited=${encode(author)}&${limit(5, page)}`),
    feed: (page) => requests.get(`/articles/feed?${limit(ARTICLE_COUNT, page)}`),
    profileFeed: (author, page) => requests.get(`/articles/profileFeed?author=${encode(author)}&${limit(ARTICLE_COUNT, page)}`),
    get: (slug) => requests.get(`/articles/${slug}`),
    unfavorite: (slug) => requests.del(`/articles/${slug}/favorite`),
    update: (article) => requests.put(`/articles/${article.slug}`, { article: omitSlug(article) }),
    create: (article) => requests.post('/articles', { article }),
};

const Comments = {
    create: (slug, comment) => requests.post(`/articles/${slug}/comments`, { comment }),
    delete: (slug, commentId) => requests.del(`/articles/${slug}/comments/${commentId}`),
    forArticle: (slug) => requests.get(`/articles/${slug}/comments`),
};

const Profile = {
    follow: (username) => requests.post(`/profiles/${username}/follow`),
    get: (username) => requests.get(`/profiles/${username}`),
    unfollow: (username) => requests.del(`/profiles/${username}/follow`),
};

export default {
    Articles,
    Auth,
    Comments,
    Profile,
    Tags,
    Stripe,
    setToken: (_token) => {
        token = _token;
    },
};
