import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import agent from '../agent';
import { ARTICLE_FAVORITED, ARTICLE_UNFAVORITED, APPLY_TAG_FILTER, ARTICLE_DELETED } from '../actionTypes';
import { ReactComponent as OptionsDots } from './Assets/options-dots.svg';
import { DELETED_TAG } from '../constants';

const FAVORITED_CLASS = 'btn btn-sm btn-primary';
const NOT_FAVORITED_CLASS = 'btn btn-sm btn-outline-primary';

const mapDispatchToProps = (dispatch) => ({
    favorite: (slug) =>
        dispatch({
            type: ARTICLE_FAVORITED,
            payload: agent.Articles.favorite(slug),
        }),
    unfavorite: (slug) =>
        dispatch({
            type: ARTICLE_UNFAVORITED,
            payload: agent.Articles.unfavorite(slug),
        }),
    onClickTag: (tag, pager, payload) =>
        dispatch({
            type: APPLY_TAG_FILTER,
            tag,
            pager,
            payload,
        }),
    onSubmit: (payload) => dispatch({ type: ARTICLE_DELETED, payload }),
});

const ArticlePreview = (props) => {
    const { article } = props;

    const onClickDelete = (ev) => {
        ev.preventDefault();
        console.log('article: ', article);
        const deletedArticle = { published: false, tagList: article.tagList.concat(DELETED_TAG) };
        const slug = { slug: article.slug };
        const promise = agent.Articles.update(Object.assign(deletedArticle, slug));
        props.onSubmit(promise);
    };

    const articleURL = props.profile ? 'published' : 'preview';
    const author = article.authorUsername;

    return (
        <div className="article-preview" style={{ borderTop: 0, paddingTop: 0, paddingBottom: '1rem' }}>
            <div className="article-meta"></div>

            <Link to={`/${articleURL}/${article.slug}`} className="preview-link">
                <h1>{article.title}</h1>
            </Link>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <span className="date" style={{ paddingBottom: '1%', color: '#aaaaaa' }}>
                    Last updated: {new Date(article.updatedAt).toDateString()}
                </span>
                {/* <span>Read more...</span> */}
                {article.tagList.length > 0 && <ul className="tag-list">
                    {article.tagList.map((tag, index) => {
                        const handleClick = (ev) => {
                            ev.preventDefault();
                            props.onClickTag(
                                tag,
                                (page) => agent.Articles.byAuthorAndTag(author, tag, page),
                                agent.Articles.byAuthorAndTag(author, tag),
                            );
                        };
                        return (
                            <li className="tag-default tag-pill tag-outline" key={`${index}${tag}`}>
                                <a href="" onClick={handleClick}>
                                    {tag}
                                </a>
                            </li>
                        );
                    })}
                </ul>}
                {!props.profile && (
                    <div className="preview-btn" style={{}}>
                        <button
                            onClick={onClickDelete}
                            style={{ backgroundColor: '#ffffff', border: '0' }}
                            title="Delete"
                        >
                            <i className='fas fa-trash-alt' style={{width: '15', height: '15'}}/>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default connect(() => ({}), mapDispatchToProps)(ArticlePreview);
