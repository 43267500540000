import { loadStripe } from "@stripe/stripe-js";
import { getStripePriceId } from "./prices";
import agent from "../agent";


// Make this function async
// export async function asyncLoadStripe() {
  let stripe;
  //console.log("process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY: ", process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  // Load the Stripe script
  loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
    apiVersion: process.env.REACT_APP_STRIPE_API_VERSION,
  }).then((stripeInstance) => {
    // Set stripe so all functions below have it in scope
    stripe = stripeInstance;
  });
//   console.log("stripe: ", stripe)
//   return stripe;
// }

export async function redirectToCheckout(planId) {
  // Create a checkout session
  //const stripe = await loadStripe();

  // TODO error handling
  const response = await agent.Stripe.create(process.env.REACT_APP_PREMIUM_PLAN);
  const session = response.data

  // Ensure if user clicks browser back button from checkout they go to /pricing
  // instead of this page or they'll redirect right back to checkout.
  window.history.replaceState({}, "", "/pricing");

  // Redirect to checkout
  return stripe.redirectToCheckout({
    sessionId: session.id,
  });
}

export async function redirectToBilling() {
  // Create a billing session
  const response = await agent.Stripe.billing();
  const session = response.data;

  // Redirect to billing session url
  window.location.href = session.url;
}
