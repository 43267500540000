import React, { useState, useEffect, useCallback } from 'react';
import enUsPatterns from 'hyphenation.en-us';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';

import Modal from '../Modal';
import agent from '../../agent';
import { justifyContent, createHyphenator } from '../../lib/linebreak';
import { generateLayoutSample } from '../../lib/content/convert-editor-preview-mobile';

import { ReactComponent as BackwardArrow } from '../Assets/backward-arrow.svg';
import { ReactComponent as OptionsDots } from '../Assets/options-dots.svg';
import { isMobile } from '../if-mobile';
import { PUBLISHED_TAG, MOBILE_WIDTH } from '../../constants';
import { styles, mobileStyles, tabletStyles } from './styles';
import { PUBLISHED_PAGE_LOADED, ARTICLE_UNPUBLISHED, OPEN_CANCEL_MODAL, 
    CLOSE_CANCEL_MODAL,  } from '../../actionTypes';

const mapStateToProps = (state) => ({
    editorState: state.common.editorState,
    articleSlug: state.published.articleSlug || window.location.pathname.split('/').pop(),
    articleBody: state.published.body,
    title: state.published.title,
    published: state.published.published,
    authorUsername: state.published.authorUsername,
    currentUsername: state.common.currentUser ? state.common.currentUser.username : null,
    tagList: state.published.tagList,
    updatedAt: state.published.updatedAt,
    currentUser: state.common.currentUser,
    modalIsActive: state.modal.cancelModalIsActive,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload) => dispatch({ type: PUBLISHED_PAGE_LOADED, payload }),
    onSubmitUnPublish: (payload) => dispatch({ type: ARTICLE_UNPUBLISHED, payload }),
    onOpenModal: () => dispatch({ type: OPEN_CANCEL_MODAL }),
    onCloseModal: () => dispatch({ type: CLOSE_CANCEL_MODAL }),
});

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function Published({
    articleSlug,
    articleBody,
    title,
    published,
    authorUsername,
    currentUsername,
    tagList,
    onLoad,
    onSubmitUnPublish,
    onOpenModal,
    onCloseModal,
    onSubmitModal,
    modalIsActive,
    currentUser,
}) {

    const [layout, setLayout] = useState();
    const bodyStyle = window.innerWidth < MOBILE_WIDTH ? mobileStyles : tabletStyles;
    const isAuthor = currentUsername === authorUsername;

    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState('comment');

    //const [hasMargins, setHasMargins] = useState(false);

    const codeRef = useCallback((node) => {
        if (node !== null) {
            highlight(node);
        }
    }, []);

    useEffect(() => {
        const slug = articleSlug || window.location.pathname.split('/').pop();
        if (slug) {
            onLoad(agent.Articles.get(slug));
        } else {
            this.props.onLoad(null);
        }
    }, []);

    // When the article body loads parse it
    useEffect(() => {
        // if (isMobile()) {
        //     const halfway = (5 * window.innerWidth) / 16;
        //     window.scrollTo(halfway, 0);
        // }
        if (articleBody && published) {
            const parsedLayout = generateLayoutSample(JSON.parse(articleBody));
            setLayout(parsedLayout);
        }
    }, [articleBody]);

    useEffect(() => {
        if (layout) {
            const hyphenate = createHyphenator(enUsPatterns);
            const htmlParas = Array.from(document.querySelectorAll('.auto-justify'));
            async function waitForFontsToLoadAndJustify() {
                let status = document.fonts.status;
                let count = 0;
                while (status === 'loading' && count < 20) {
                    await timeout(500);
                    count += 1;
                    status = document.fonts.status;
                }
                justifyContent(htmlParas, hyphenate)
                //setHasMargins(layout.leftMargin.length > 0 || layout.rightMargin.length > 0);
            }
            waitForFontsToLoadAndJustify()
        }
    }, [layout]);

    function unPublishArticle() {
        const slug = { slug: articleSlug };
        const filteredTags = tagList && tagList.filter((tag) => tag != PUBLISHED_TAG);
        const article = { published: false, tagList: filteredTags };
        const promise = agent.Articles.update(Object.assign(article, slug));
        onSubmitUnPublish(promise);
    }

    function highlight(node) {
        if (node) {
            const pre = node.querySelectorAll('pre');
            if (pre) {
                pre.forEach((n) => {
                    hljs.highlightBlock(n);
                });
            }
        }
    }

    return (
        <div>
            <div id="content-body" style={bodyStyle.contentBody}>
                {title && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div id="titleBlock" style={bodyStyle.title}>
                            <p>{title}</p>
                        </div>
                    </div>
                )}
                {/* {authorUsername && (
                    <div id="authorBlock" className="auto-justify" >
                        <div>
                            <Link className="author" to={`/@${authorUsername}`} target={'_blank'}>
                                Written by: {authorUsername}
                            </Link>
                        </div>
                    </div>
                )} */}
                {layout && (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div id="col1" style={bodyStyle.body}>
                            {layout.body.map((block, index) => {
                                if (block.image) {
                                    return (
                                        <figure
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                        >
                                            <img
                                                key={`r-${index}`}
                                                className="test"
                                                style={bodyStyle.image}
                                                src={block.url}
                                                alt="test"
                                            />
                                            <figcaption style={{...bodyStyle.image, padding: '1em'}} dangerouslySetInnerHTML={{ __html: block.text }}></figcaption>
                                        </figure>
                                    );
                                } else if (block.code) {
                                    return (
                                        <div
                                            key={`l-${index}`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <p
                                                id={block.i}
                                                style={{ maxWidth: '600px', minWidth: '600px' }}
                                                className={'auto-justifyx output-px'}
                                                dangerouslySetInnerHTML={{ __html: block.text }}
                                                ref={codeRef}
                                            >
                                                {/* {block.text} */}
                                            </p>
                                        </div>
                                    );
                                } else if (block.blockQuote) {
                                    return (
                                        <div
                                            key={`l-${index}`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <blockquote
                                                id={block.i}
                                                style={{
                                                    maxWidth: '600px',
                                                    minWidth: '600px',
                                                    fontSize: '18pt',
                                                    padding: '0.5em',
                                                    borderLeft: '2px solid #000',
                                                }}
                                                className={'auto-justify output-p'}
                                                dangerouslySetInnerHTML={{ __html: block.text }}
                                                ref={codeRef}
                                            >
                                                {/* {block.text} */}
                                            </blockquote>
                                        </div>
                                    );
                                }
                                return (
                                    <div key={index}>
                                        <p
                                            id={block.i}
                                            className={'auto-justify output-p'}
                                            dangerouslySetInnerHTML={{ __html: block.text }}
                                        >
                                            {/* {block.text} */}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            {layout.margin && layout.margin.map(margin => {
                                console.log("margin: ", margin)
                                return (
                                    <div key={margin.index}>
                                    <p
                                        id={margin.i}
                                        className={'auto-justify output-p'}
                                        dangerouslySetInnerHTML={{ __html: `<a id=fn-${margin.index} href="#root-${margin.index}">[${margin.index}]</a>: ${margin.text}` }}
                                    >
                                        {/* {block.text} */}
                                    </p>
                                </div>
                                )
                            })}
                        </div>

                    </div>
                )}
                <div>
                    <div style={{
                        fontFamily: "'Spectral', serif",
                        fontSize: '12pt',
                        margin: '3rem',
                        fontStyle: 'italic',
                    }}>Feedback, thoughts? Let me know <a href="https://twitter.com/andrewbrownmart">@andrewbrownmart</a></div>
                </div>
                <Modal
                    isActive={modalIsActive}
                    onClose={onCloseModal}
                    modalType={modalType}
                    user={currentUser}
                    onSubmitModal={onSubmitModal}
                    content={modalContent}
                />
            </div>
        </div>
    );
}

Published.propTypes = {
    articleSlug: PropTypes.string,
    articleBody: PropTypes.string,
    title: PropTypes.string,
    published: PropTypes.bool,
    tagList: PropTypes.array,
    currentUsername: PropTypes.string,
    authorUsername: PropTypes.string,
    updatedAt: PropTypes.string,
    onLoad: PropTypes.func,
    onSubmitUnPublish: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Published);
