import DOMPurify from 'dompurify';
import { SAMPLE_EDITOR_CONTENT } from './sample-editor-content';

export function generateLayoutSample(input = SAMPLE_EDITOR_CONTENT) {
    const layout = textLayoutWithParsing(input);

    return {
        leftBody: layout.filter((block) => block.left && !block.margin && !block.title),
        rightBody: layout.filter((block) => !block.left && !block.margin && !block.title),
        body: layout.filter((block) => !block.margin && !block.title),
        leftMargin: layout.filter((block) => block.margin && block.left),
        rightMargin: layout.filter((block) => block.margin && !block.left),
        margin: layout.filter((block) => block.margin),
        title: layout.filter((block) => block.title),
    };
}

function parseLeftRight(text, index) {
    if (text.includes('{{&L')) {
        return 'left';
    }
    if (text.includes('{{&R')) {
        return 'right';
    }
    return index % 2 ? 'right' : 'left';
}

const MATCH_PATTERNS = [
    '{{+',
    '{{-',
    '{{&L+',
    '{{&L-',
    '{{&R+',
    '{{&R-',
    '{{&L#',
    '{{&R#',

]

function splitText(text) {
    // match returns the pattern to split on down below
    const match = MATCH_PATTERNS.find((pattern) => text.startsWith(pattern));
    let adjuster = 0;
    let lengthOfSplit = 0;
    let symbol = ''

    if (match) {
        // number to adjust the top padding by
        adjuster = text.split(match)[1].split(' ')[0];
        try {
            adjuster = match[match.length - 1] === '-' ? -1 * Number(adjuster) : Number(adjuster);
            adjuster *= match[match.length - 1] === '#' ? 1 : 5
            symbol = match[match.length - 1] === '#' ? '#' : ''
        } catch (err) {
            console.log('err: ', err);
            console.log('adjuster NAN');
        }
        lengthOfSplit += match.length + adjuster.toString().length

    }
    return { adjuster, lengthOfSplit, symbol};
}

function removeMarkup(text) {
    return text
        .split(/\s(.+)/)[1]
        .replace('}}', '')
        .trim();
}

function computePlaceholderLength(blockText, lengthOfSplit) {
    if (lengthOfSplit === 0 ) {
        if (blockText.includes("{{&L") || blockText.includes("{{&R")) {
            lengthOfSplit = 5;
        } else {
            lengthOfSplit = 3;
        }
    }
    return lengthOfSplit;
}

// Return object for margins with text+ styling and object for body with text and styling
function parseOutMargin(block, entityMap, totalTextLength, marginCounter) {
    const re = /\{\{.+?\}\}/g;
    const marginNotes = [];
    let match;
    let index = 0;
    let bodyStyles = block.inlineStyleRanges
            .concat(block.entityRanges
                .map(entity => ({ ...entity, style: 'LINK' })))
    while ((match = re.exec(block.text)) != null) {
        const position = parseLeftRight(match[0], index);
        let { adjuster, lengthOfSplit, symbol} = splitText(match[0]);

        // The indexing of the inline styles depends on the markup being in the text string
        // but we've already removed the markup so we need to add placeholders otherwise the
        // indexing will be off.
        const placeholderLength = computePlaceholderLength(block.text, lengthOfSplit);
        const marginCommentStartIndex = block.text.indexOf(match[0])

        const marginStyles = block.inlineStyleRanges
            .concat(block.entityRanges
                .map(entity => ({ ...entity, style: 'LINK' })))
            .filter(style => {
                if (style.offset >= marginCommentStartIndex && style.offset < marginCommentStartIndex + match[0].length) {
                    return true;
                }
                return false;
            });

        // Bad functional programming. We want to iteratively remove all the margin styles from the body styles
        bodyStyles = bodyStyles
            .filter(style => {
                if (style.offset < marginCommentStartIndex || style.offset > marginCommentStartIndex + match[0].length) {
                    return true;
                }
                return false;
            });

        const marginComment = removeMarkup(match[0]);
        const originalText = `${'x'.repeat(placeholderLength)}${marginComment}`;
        const inlineStyles = stylingCombinations(marginStyles, entityMap, marginCommentStartIndex);
        marginNotes.push({
            start: match.index,
            end: match.index + match[0].length,
            text: applyStyling(block, originalText, inlineStyles, marginCommentStartIndex).slice(placeholderLength), // match[0].replace("{{&L " ,"").replace("{{&R ", "").replace("{{", "").replace("}}", ""),
            position: position === 'left' ? 'left' : 'right',
            left: position === 'left',
            title: false,
            block,
            image: block.type === 'image',
            code: block.type === 'code-block',
            margin: true,
            y: symbol === '#' ? adjuster : totalTextLength[position] + adjuster, // I may need to keep track of how large the document is to give a good read here
            fullMatch: match,
            index: marginCounter.count,
        });
        index += 1;
        
        marginCounter.notes[match] = marginCounter.count
        marginCounter.count += 1
        
        if (position === 'left') {
            totalTextLength.left = 0;
        } else {
            totalTextLength.right = 0;
        }
    }
    console.log("index: ", marginCounter)
    let newText = block.text;
    marginNotes.forEach((note) => {
        const fnKey = marginCounter.notes[note.fullMatch]
        newText = newText.replace(note.fullMatch, `<a id="root-${fnKey}" href="#fn-${fnKey}">[${fnKey}]</a>`); // newText.replace("{{&L " ,"").replace("{{&R ", "").replace("{{", "").replace("}}", "").replace(note.text, "")
    });

    return {
        marginContent: {
            text: marginNotes,
        }, 
        bodyContent: {
            text: newText,
            styles: bodyStyles
        }
    };
}

function getLink(entity) {
    if (!entity) {
        return '';
    }
    if (entity.data && entity.data.url) {
        return entity.data.url;
    }
    return '';
}

function stylingCombinations(inlineStyles, entityMap, baseIndex=0) {

    if (!inlineStyles || inlineStyles.length === 0) {
        return [];
    }

    let merged = [];
    const starts = inlineStyles.map((style) => ({ index: style.offset, start: 1, prop: style.style, link: getLink(entityMap[style.key]) }));
    const ends = inlineStyles.map((style) => ({ index: style.offset + style.length, start: -1, prop: style.style, link: getLink(entityMap[style.key]) }));
    // eslint-disable-next-line prettier/prettier
    merged = merged.concat(starts);
    merged = merged.concat(ends);
    merged = merged.sort((a,b) => (a.index > b.index ? 1 : -1));

    let activeProps = [];
    let activeLink = '';
    const intervals = [];
    let lastCoord = { index: baseIndex };
    merged.forEach((coord, a) => {
        if (coord.index === baseIndex) {
            lastCoord = coord;
            activeProps.push(coord.prop);
            if (coord.prop === 'LINK') {
                activeLink = coord.link;
            }
            return;
        }
        intervals.push({
            start: lastCoord.index,
            end: coord.index,
            prop: activeProps.map((a) => a),
            link: activeLink,
        });
        lastCoord = coord;

        if (coord.start === 1) {
            activeProps.push(coord.prop);
            if (coord.prop === 'LINK') {
                activeLink = coord.link;
            }
        } else if (coord.start === -1) {
            // eslint-disable-next-line prettier/prettier
            activeProps = activeProps.filter(prop => prop !== coord.prop);
            if (coord.prop === 'LINK') {
                activeLink = '';
            }
        }
    });
    return intervals;
}

function getStyleMap(textStyle, close) {
    let styles;
    let color = '';

    const props = textStyle.prop.map((prop) => {
        if (prop.includes('CUSTOM_COLOR')) {
            color = prop.split('_')[2];
            return 'CUSTOM_COLOR';
        }
        return prop;
    });
    if (close) {
        styles = [
            { key: 'BOLD', markup: '</b>' },
            { key: 'ITALIC', markup: '</i>' },
            { key: 'CUSTOM_COLOR', markup: '</font>'},
            { key: 'LINK', markup: `</a>` }
        ];
    } else {
        styles = [
            { key: 'LINK', markup: `<a href=${textStyle.link} target="_blank">`},
            { key: 'CUSTOM_COLOR', markup: `<font color=${color}>` },
            { key: 'ITALIC', markup: '<i>' },
            { key: 'BOLD', markup: '<b>' },
        ];
    }
    return styles
        .filter((style) => props.includes(style.key))
        .map((style) => style.markup)
        .join('');
}

function applyStyling(block, blockText, inlineStyles, baseIndex=0) {
    let textAsHTML = blockText;
    const originalText = blockText;
    let link = false;

    let lastIndex = 0;
    if (inlineStyles.length > 0) {
        inlineStyles.forEach((style, a) => {
            if (style.start === style.end) {
                return;
            }

            if (style.prop.includes('LINK')) {
                link = true;
            }

            const start = style.start - baseIndex;
            const end = style.end - baseIndex;

            if (a === 0) {
                textAsHTML = `${originalText.slice(0, start)}${getStyleMap(
                    style,
                    false,
                )}${originalText.slice(start, end)}${getStyleMap(style, true)}`;
            } else {
                textAsHTML += `${getStyleMap(style, false)}${originalText.slice(
                    start,
                    end,
                )}${getStyleMap(style, true)}`;
            }
            lastIndex = end;
        });
        if (lastIndex < originalText.length) {
            textAsHTML += `${originalText.slice(lastIndex)}`;
        }
    }

    if (block.type !== 'unstyled') {
        if (block.type === 'header-three') {
            textAsHTML = `<h3 style="white-space: break-spaces;margin:0;margin-top:1em;">${textAsHTML}</h3>`;
        } else if (block.type === 'header-two') {
            textAsHTML = `<h2 style="white-space: break-spaces;">${textAsHTML}</h2>`;
        } else if (block.type === 'header-one') {
            textAsHTML = `<h1 style="white-space: break-spaces;">${textAsHTML}</h1>`;
        } else if (block.type === 'code-block') {
            textAsHTML = `<pre><code>${textAsHTML}</code></pre>`
        } else if (block.type === 'unordered-list-item') {
            if (link) {
                textAsHTML =  `<ul style="white-space: normal;"><li>${textAsHTML}</li></ul>`
            } else {
                textAsHTML =  `<ul style="white-space: normal;">${textAsHTML.split("\n").filter(text => text.length > 0).map(text => (`<li>${text}</li>`)).join('')}</ul>`
            }
        } else if (block.type === 'ordered-list-item') {
            if (link) {
                textAsHTML =  `<ol style="white-space: normal;"><li>${textAsHTML}</li></ol>`
            } else {
                textAsHTML = `<ol style="white-space: normal;">${textAsHTML.split("\n").filter(text => text.length > 0).map(text => (`<li>${text}</li>`)).join('')}</ol>`
            }
        }
    }
    return textAsHTML;
}

var tagsToReplace = {
    //&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
};

function replaceTag(tag) {
    return tagsToReplace[tag] || tag;
}

function safe_tags_replace(str) {
    return str.replace(/[&<>]/g, replaceTag);
}

function textLayoutWithParsing(input) {
    const samples = input;

    const numberBlocks = samples.blocks.length;
    const totalTextLength = {
        left: 0,
        right: 0,
    };

    const entityMap = samples.entityMap;

    // Want to do a step merging all contiguous code blocks together
    let inCodeBlock = false;
    const mergedSamples = [];
    let accumulatedCodeString = ''
    const maxIndex = samples.blocks.length - 1;
    samples.blocks.forEach((block, index) => {
        console.log("block: ", block)
        if (['unordered-list-item', 'ordered-list-item', 'code-block'].includes(block.type)) {
            inCodeBlock = true;
            if (block.text === '') {
                accumulatedCodeString += '\n';
            } else {
                accumulatedCodeString += `\n${block.text}`;
            }
        }

        // We're leaving a code block
        if (!['unordered-list-item', 'ordered-list-item', 'code-block'].includes(block.type) && inCodeBlock) {
            const newBlock = {
                ...samples.blocks[index - 1],
                text: safe_tags_replace(DOMPurify.sanitize(accumulatedCodeString)),
            };
            mergedSamples.push(newBlock);
            inCodeBlock = false;
            accumulatedCodeString = '';

            //return;
        }

        if (!inCodeBlock) {
            mergedSamples.push({
                ...block,
                text: safe_tags_replace(DOMPurify.sanitize(block.text)),
            })
        } else if (index === maxIndex && accumulatedCodeString.length > 0) {
            const newBlock = {
                ...samples.blocks[index - 1],
                text: safe_tags_replace(DOMPurify.sanitize(accumulatedCodeString)),
            };
            mergedSamples.push(newBlock);
            inCodeBlock = false;
            accumulatedCodeString = '';
            return;
        } 
    });

    const marginCounter = { count: 1, notes: {} }
    const bodySamples = mergedSamples.map((block, index) => {
        // Question for tomorrow: how does this work with inline margin comments?
        const { marginContent, bodyContent } = parseOutMargin(block, entityMap, totalTextLength, marginCounter);
        const inlineStyles = stylingCombinations(bodyContent.styles, entityMap);
    
        totalTextLength.left += bodyContent.text.length;
        totalTextLength.right += bodyContent.text.length;

        const blockContent =
            bodyContent.text.length > 0 || block.type === 'image'
                ? [
                    {
                        left: index / numberBlocks <= 0.55,
                        url: block.type === 'image' ? block.data.url : '',
                        title: false,
                        image: block.type === 'image',
                        code: block.type === 'code-block',
                        blockQuote: block.type === 'blockquote',
                        block,
                        margin: false,
                        text: applyStyling(block, bodyContent.text, inlineStyles),
                    },
                ]
                : [];
        return blockContent.concat(marginContent.text);
    });
    return [].concat.apply([], bodySamples);
}
