import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import agent from '../agent';
import { UPDATE_FIELD_AUTH, REGISTER, REGISTER_PAGE_UNLOADED } from '../actionTypes';

const mapStateToProps = (state) => ({
    ...state.auth,
    currentUser: state.common.currentUser,
});

const styles = {
    plan: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    price: {
        fontSize: '24pt',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5%',
    },
};

const mapDispatchToProps = (dispatch) => ({
    onChangeEmail: (value) => dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
    onChangePassword: (value) => dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
    onChangeUsername: (value) => dispatch({ type: UPDATE_FIELD_AUTH, key: 'username', value }),
    onSubmit: (username, email, password) => {
        const payload = agent.Auth.register(username, email, password);
        dispatch({ type: REGISTER, payload });
    },
    onUnload: () => dispatch({ type: REGISTER_PAGE_UNLOADED }),
});

function SelectPlan(props) {
    return (
        <div className="select-plan-page">
            <div className="container page">
                <div id="topLevel" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div id="testimonial">

                    </div>
                    <div id="prices" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <div id="price-1">
                            <div className="pricingBox" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', border: '2px solid #000', padding: '2em'}}>
                                <div className="planName" style={{height: '15%', backgroundColor: 'lightcoral'}}>
                                    Basic
                                </div>
                                
                            </div>

                        </div>
                        <div id="price-2">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

class SelectPlanx extends React.Component {
    constructor() {
        super();
        this.changeEmail = (ev) => this.props.onChangeEmail(ev.target.value);
        this.changePassword = (ev) => this.props.onChangePassword(ev.target.value);
        this.changeUsername = (ev) => this.props.onChangeUsername(ev.target.value);
        this.submitForm = (username, email, password) => (ev) => {
            ev.preventDefault();
            this.props.onSubmit(username, email, password);
        };
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    componentDidMount() {
        const braintreeScriptTag = document.createElement('script');
        braintreeScriptTag.src = 'https://js.braintreegateway.com/web/dropin/1.23.0/js/dropin.min.js';
        document.head.appendChild(braintreeScriptTag);
    }

    render() {
        const { email } = this.props;
        const { password } = this.props;
        const { username } = this.props;

        return (
            <div className="auth-page">
                <div className="container page">
                    <div className="row" style={styles.row}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            I've never felt so motivated to write as when I use Babel - Sean L (premium plan user)
                        </div>
                    </div>
                    <div className="row" style={styles.row}>
                        <div className="free-plan" style={styles.plan}>
                            <div className="discount" style={{ color: '#fff' }}>
                                special launch price of $3.99/month
                            </div>
                            <div className="price" style={styles.price}>
                                $0 / month
                            </div>
                            <div className="benefits">
                                <ul id="1">Write and preview unlimited work</ul>
                                <ul id="2">Read unlimited writing</ul>
                                <ul id="3">Free forever</ul>
                            </div>
                            <div className="action">
                                <Link to="/register">
                                    <button
                                        className="btn btn-lg pull-xs-right btn-primary"
                                        type="button"
                                        style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                                        disabled={this.props.inProgress}
                                    >
                                        {this.props.currentUser && !this.props.currentUser.subscription
                                            ? 'Current Plan'
                                            : 'Select'}
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div
                            style={{
                                borderLeft: '2px solid black',
                                height: '250px',
                                //left: '50%',
                                marginLeft: '6px',
                            }}
                        />
                        <div className="premium-plan" style={styles.plan}>
                            <div className="discount">special launch price of $3.99/month</div>
                            <div
                                className="price"
                                style={{
                                    fontSize: '24pt',
                                    textDecorationLine: 'line-through',
                                    textDecorationColor: '#5485bb',
                                }}
                            >
                                $12.99 / month
                            </div>
                            <div className="benefits">
                                <ul id="1">Write and preview and publishd unlimited work</ul>
                                <ul id="2">Read unlimited writing</ul>
                                <ul id="3">Free forever</ul>
                            </div>
                            <div className="action">
                                <Link to="/checkout">
                                    <button
                                        className="btn btn-lg pull-xs-right btn-primary"
                                        type="button"
                                        style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                                        disabled={this.props.inProgress}
                                    >
                                        Select
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div />
                        <div
                            className="row"
                            style={{
                                marginTop: '10%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        />
                        <div>Interested in self hosting? contact us</div>
                        <div>Enterprise, magazine, or publication? contact us</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlan);
