import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import article from './reducers/article';
import articleList from './reducers/articleList';
import auth from './reducers/auth';
import common from './reducers/common';
import editor from './reducers/editor';
import preview from './reducers/preview';
import published from './reducers/published';
import home from './reducers/home';
import profile from './reducers/profile';
import settings from './reducers/settings';
import modal from './reducers/modal';

function createRootReducer(history) {
    return combineReducers({
        article,
        articleList,
        auth,
        common,
        editor,
        preview,
        home,
        profile,
        settings,
        router: connectRouter(history),
        published,
        modal,
    });
}

export default createRootReducer;
