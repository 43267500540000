import React from 'react';
import { connect } from 'react-redux';
import agent from '../agent';
import ListErrors from './ListErrors';
import {
    SETTINGS_SAVED,
    SETTINGS_PAGE_UNLOADED,
} from '../actionTypes';


class SettingsForm extends React.Component {
    constructor() {
        super();

        this.state = {
            image: '',
            username: '',
            bio: '',
            email: '',
            password: '',
            passwordConfirm: '',
            paswordsMatch: true,
            imageChanged: false,
        };

        this.updateState = (field) => (ev) => {
            const { state } = this;
            let passwordsMatch = true;


            const newState = { ...state, [field]: ev.target.value, passwordsMatch, };
            if (field.includes('password') && newState.password !== newState.passwordConfirm) {
                passwordsMatch = false;
            }
            this.setState(newState);
        };

        this.submitForm = async (ev) => {
            ev.preventDefault();

            // Confirm new password and confirm password match.
            if (!this.state.passwordsMatch) {
                return <p>Passwords do not match</p>
            }

            const user = { ...this.state };
            if (!user.password) {
                delete user.password;
            }
            // This should redirect to a welcome and how-to-use form with video
            this.props.onSubmitForm(user);
        };
    }

    componentWillMount() {
        if (this.props.currentUser) {
            Object.assign(this.state, {
                username: '',
                bio: this.props.currentUser.bio,
                email: '',
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentUser) {
            this.setState({
                ...this.state,
                username: '', 
                bio: nextProps.currentUser.bio,
                email: '', 
            });
        }
    }

    render() {
        return (
            <form onSubmit={this.submitForm} encType="multipart/form-data">
                <fieldset>
                    <fieldset className="form-group">
                        <label for="username">Username</label>
                        <input
                            id="username"
                            className="form-control form-control-lg"
                            type="text"
                            required
                            placeholder="Username"
                            value={this.state.username}
                            onChange={this.updateState('username')}
                        />
                    </fieldset>
                    <fieldset className="form-group">
                        <label for="email">Email address</label>
                        <input
                            id="email"
                            className="form-control form-control-lg"
                            type="email"
                            placeholder="Email"
                            required
                            value={this.state.email}
                            onChange={this.updateState('email')}
                        />
                    </fieldset>

                    <fieldset className="form-group">
                        <label for="password">Set Password</label>
                        <input
                            id="password"
                            className="form-control form-control-lg"
                            type="password"
                            minLength="8"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.updateState('password')}
                        />
                    </fieldset>
                    <fieldset className="form-group">
                        <label for="passwordConfirm">Confirm Password</label>
                        <input
                            id="passwordConfirm"
                            className="form-control form-control-lg"
                            type="password"
                            minLength="8"
                            placeholder="Confirm Password"
                            value={this.state.passwordConfirm}
                            onChange={this.updateState('passwordConfirm')}
                        />
                    </fieldset>

                    <button
                        className="btn btn-lg btn-primary pull-xs-right"
                        type="submit"
                        disabled={this.state.inProgress}
                    >
                        Register
                    </button>
                </fieldset>
            </form>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.settings,
    currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitForm: (user) => dispatch({ type: SETTINGS_SAVED, payload: agent.Auth.save(user) }),
    onUnload: () => dispatch({ type: SETTINGS_PAGE_UNLOADED }),
});

class Registration extends React.Component {
    render() {
        return (
            <div className="settings-page">
                <div className="container page">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 col-xs-12">
                            <h1 className="text-xs-center">Register your account</h1>
                            <br />

                            <ListErrors errors={this.props.errors} />

                            <SettingsForm currentUser={this.props.currentUser} onSubmitForm={this.props.onSubmitForm} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
