import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { customAlphabet } from 'nanoid';

import { BABEL_DOMAINS } from '../constants';
import { ReactComponent as PlusSign } from './Assets/plus-sign.svg';
import { isMobile } from './if-mobile';
import agent from '../agent';

const nanoid = customAlphabet('1234567890abcdef', 10)

const LoggedOutView = (props) => {
    const domain = window.location.hostname;
    let showSignUp = false;
    if (BABEL_DOMAINS.includes(domain)) {
        showSignUp = true;
    }


    const dispatch = useDispatch();
    async function getStarted() {
        if (!props.currentUser) {
            const username = `babeltempuser${nanoid(10)}`;
            const password = 'random-password';
            const email = `${username}@babeltemp.com`;
            const payload = await agent.Auth.register(username, email, password);
            dispatch({ type: 'REGISTER', payload });
            dispatch({ type: 'REDIRECT', payload: {redirectUrl: '/pricing' }})
        } else {
            dispatch({ type: 'REDIRECT', payload: {redirectUrl: '/pricing' }})
        }
    }

    if (!props.currentUser || props.currentUser.username.includes('babeltempuser')) {
        return (
              <ul className="nav navbar-nav pull-xs-right">
                {showSignUp && !isMobile() && <li className="nav-item">
                    {/* <Link to="/blog/free-writing-platform" className="nav-link"> */}
                        <a className="nav-link" href="https://andrewbmartin.com">Home</a>
                    {/* </Link> */}
                </li>}

                <li className="nav-item">
                    {/* <Link to="/login" className="nav-link" > */}
                    <a className="nav-link" href="https://andrewbmartin.com/#writing">Writing</a>
                    {/* </Link> */}
                </li>

                {showSignUp && <li className="nav-item">
                    {/* <Link to="/pricing" className="nav-link" onClick={()=> getStarted()} style={{cursor: 'pointer'}}> */}
                    <a className="nav-link" href="https://andrewbmartin.com/#feedback">Feedback</a>
                    {/* </Link> */}
                </li>}

                {!showSignUp && <li className="nav-item">
                    <Link to={`/`} className="nav-link">
                        <img
                            src={'https://cdn.trollcleaner.com/babel/userImages/andrew/Sj05jhAvDP5Eayfm0LB_-.jpeg'}
                            className="abm-picture"
                            style={userPicStyles}
                            alt={'home'}
                        />
                    </Link>
                </li>}
            </ul>
        );
    }
    return null;
};

const maxUserPicStyles = {
    height: '60px',
    borderRadius: '50px',
    float: 'left',
    marginRight: '5px',
};

const minUserPicStyles = {
    height: '35px',
    borderRadius: '100px',
    float: 'left',
    //marginRight: '5px',
};

const userPicStyles = window.innerWidth < 800 ? minUserPicStyles : maxUserPicStyles;

const pullXsRightClass = window.innerWidth < 800 ? 'pull-xs-right-mobile' : 'pull-xs-right';

const LoggedInView = (props) => {

    if (props.currentUser && !props.currentUser.username.includes('babeltempuser')) {
        return (
            <ul className={`nav navbar-nav ${pullXsRightClass}`} style={{alignItems: 'center'}}>
                {!props.currentUser.subscription && (
                    <li className="nav-item">
                        <div className="nav-link" >
                           <a href="/pricing" target="_blank">Upgrade</a> 
                        </div>
                    </li>
                )}

                <li className="nav-item">
                    <Link to="/editor" className="nav-link" title="Write">
                        <PlusSign style={userPicStyles} />
                    </Link>
                </li>

                
                {/* <li className="nav-item">
                    <Link
                        to="/settings"
                        className="nav-link-mobile"
                        style={{ display: 'block', paddingTop: '0.425rem' }}
                    >
                        <i className="icon-cog" style={{ fontSize: '35px' }} />
                    </Link>
                </li> */}

                <li className="nav-item">
                    <Link to={`/settings`} className="nav-link">
                        <img
                            src={props.currentUser.image}
                            className="user-picture"
                            style={userPicStyles}
                            alt={props.currentUser.username}
                        />
                    </Link>
                </li>
            </ul>
        );
    }
    return null;
};

const maxLogoStyles = { maxWidth: '200px', maxHeight: '75px', paddingTop: '0.425rem', paddingBottom: '0.425rem' };
const minLogoStyles = { maxWidth: '150px', maxHeight: '85px', paddingTop: '0.425rem', paddingBottom: '0.425rem' };
const logoStyles = window.innerWidth < 800 ? minLogoStyles : maxLogoStyles;

const selectLocation = (state) => state && state.router && state.router.location && state.router.location.pathname;

// This depends on the URL not no the user...
function applyLogo(user) {

    const domain = window.location.hostname;

    // Need domain to mapping object

    // Don't show a logo before it's loaded.
    // if (!user && !BABEL_DOMAINS.includes(domain)) {
    //     return null;
    // }

    if (user && user.logoImage && domain === user.customDomain) {
        return <img src={user.logoImage} style={logoStyles}/>
    }

    return <img src='https://cdn.trollcleaner.com/andrew_color_logo.svg' style={logoStyles} />
}

function Header(props) {
    const pathname = useSelector(selectLocation);
    let headerStyles = { padding: '0px' };

    // Top left logo... if domain then don't show sign-up. 

    if (isMobile() && (pathname.includes('/preview/') || pathname.includes('/published/'))) {
        headerStyles = {
            ...headerStyles,
            display: 'flex',
            justifyContent: 'center',
        }

    }

    // If logo url is different than standard return a customer <a> element.
    // Logo URL is all I need to get started.
    // Could also be an upload and then the URL is the s3 address.
    // Want to make image locations CDN.s

    return (
        <nav className="navbar navbar-light" style={headerStyles}>
            <div className="container" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                {props.currentUser && !props.currentUser.username.includes('babeltempuser') ? ( 
                    <Link to="/home" className="navbar-brand" style={{ paddingBottom: '0px' }}>
                        {applyLogo(props.currentUser)}
                    </Link>
                ) : (
                    <div>
                    <a href="https://www.andrewbmartin.com/" target="_blank">
                        {applyLogo(props.currentUser)}
                    </a>
                    </div>
                )}

                <div>
                    <LoggedOutView currentUser={props.currentUser} />
                </div>

                <LoggedInView currentUser={props.currentUser} onOpenModal={props.onOpenModal} />
            </div>
        </nav>
    );
}

export default Header;
