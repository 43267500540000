import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import agent from '../agent';
import { PROFILE_PAGE_LOADED } from '../actionTypes';
import { PUBLISHED_TAG, BABEL_DOMAINS } from '../constants';
import ArticlesAndTags from './ArticlesAndTags';

const mapStateToProps = (state) => ({
    currentUser: state.common.currentUser,
    bio: state.profile.bio,
    image: state.profile.image,
    profilePath: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: (payload) => dispatch({ type: PROFILE_PAGE_LOADED, payload }),
});

function Profile({ currentUser, profilePath, bio, image, onLoad }) {
    const domain = window.location.hostname
    const domainToProfileUsername = {
        'andrewbmartin.herokuapp.com': 'andrew',
        'andrewbmartin.com': 'andrew',
        'localhost:4100': 'andrew',
        'writing.andrewbmartin.com': 'andrew',
        'www.andrewbmartin.com': 'andrew',
    }

    let profileUsername;
    if (BABEL_DOMAINS.includes(domain)) {
        profileUsername = profilePath.split('@')[1];
    } else if (profilePath === 'blog'){
        profileUsername = 'teambabel' 
    } else {
        profileUsername = domainToProfileUsername[domain];
    }

    const isUser = currentUser ? currentUser.username === profileUsername : false;

    useEffect(() => {
        onLoad(
            Promise.all([
                agent.Profile.get(profileUsername),
                agent.Articles.byAuthorAndTag(profileUsername, PUBLISHED_TAG),
            ]),
        );
    }, []);

    return (
        <div className="profile-page">
            <div className="container">
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Link to={isUser ? `/settings` : `/@${profileUsername}`}>
                        <img
                            src={image}
                            alt={profileUsername}
                            style={{
                                height: '120px',
                                borderRadius: '100px',
                            }}
                        />
                    </Link>
                    <div className="info">
                        <Link className="author" to={`/@${profileUsername}`}>
                            {profileUsername === 'teambabel' ? 'Team Babel' : profileUsername}
                        </Link>
                    </div>
                    <div className="bio" style={{padding: '1em'}}>{bio}</div>
                    {profileUsername === 'andrew' && (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://twitter.com/AndrewBrownMart"
                        >
                            <img
                                src="https://uploads.divjoy.com/icon-twitter.svg"
                                alt="Twitter"
                            />
                            &nbsp;@AndrewBrownMart
                        </a>
                    )}
                    {profileUsername === 'teambabel' && (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://twitter.com/BabelWriting"
                        >
                            <img
                                src="https://uploads.divjoy.com/icon-twitter.svg"
                                alt="Twitter"
                            />
                            &nbsp;@BabelWriting
                        </a>
                    )}
                </div>
                <ArticlesAndTags isProfile={true} username={profileUsername} />
            </div>
        </div>
    );
}

Profile.propTypes = {
    currentUser: PropTypes.string,
    profilePath: PropTypes.string,
    bio: PropTypes.string,
    image: PropTypes.string,
    pager: PropTypes.func,
    articles: PropTypes.array,
    articlesCount: PropTypes.number,
    currentPage: PropTypes.number,
    onLoad: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
