export const styles = {
    body: {
        maxWidth: '650px',
        // marginTop: '4em',
        // paddingLeft: '2%'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: '2%'
    },
    title: {
        fontFamily: "'Rubik', sans-serif",
        fontSize: '24pt',
        whiteSpace: 'normal',
        maxWidth: '600px',
        marginTop: '1em',
        // position: 'absolute',
        //padding: '10px',
        // paddingLeft: '2%'
    },
    marginStyles: (y) => ({
        // width: '200%',
        //paddingTop: `${10 + y / 32.5}em`,
        top: `${Math.max(50, y - 190)}px`,
        position: 'relative',
        // maxWidth: '150px',
        maxWidth: '17em',
        fontSize: '10pt',
    }),
    leftMargin: {
        // paddingRight: '4%',
        marginTop: '2em',
        textAlign: 'right',
        maxWidth: '20%',
        minWidth: '20%',
    },
    rightMargin: {
        // paddingLeft: '9%',
        marginTop: '2em',
        textAlign: 'left',
        maxWidth: '20%',
        minWidth: '20%',
    },
    image: { maxWidth: '450px', fontSize: '10pt' },
};

export const mobileStyles = {
    body: {
        //maxWidth: '325px',
        minWidth: '325px',
        //marginTop: '1em',
        // paddingLeft: '2%'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //minWidth: '30em',
        overflowX: 'hidden',
        // padding: '2%'
    },
    title: {
        fontFamily: "'Rubik', sans-serif",
        fontSize: '18pt',
        // position: 'absolute',
        // paddingLeft: '2%'
        fontWeight: '600',
        padding: '1em',
        paddingBottom: '0',
        //maxWidth: '15em',
        minWidth: '325px',
        maxWidth: '325px',
        marginTop: '1em',
    },
    marginStyles: (y) => ({
        // width: '200%',
        paddingTop: `${10 + y / 20}em`,
        maxWidth: '75px', //'17em', //'150px',
        minWidth: '75px', //'15em',
        fontSize: '10pt',
        overflowX: 'hidden',
    }),
    leftMargin: {
        // paddingRight: '4%',
        marginTop: '4em',
        textAlign: 'right',
        maxWidth: '75px',
        minWidth: '75px',
        //marginLeft: '2em',
        //paddingLeft: '5em',
    },
    rightMargin: {
        // paddingLeft: '9%',
        marginTop: '4em',
        textAlign: 'left',
        //marginRight: '2em',
        //paddingRIght: '5em',
    },
    image: { maxWidth: '250px', fontSize: '10pt' },
};

export const tabletStyles = {
    body: {
        //maxWidth: '325px',
        minWidth: '425px',
        //marginTop: '1em',
        // paddingLeft: '2%'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //minWidth: '30em',
        overflowX: 'hidden',
        // padding: '2%'
    },
    title: {
        fontFamily: "'Rubik', sans-serif",
        fontSize: '18pt',
        // position: 'absolute',
        // paddingLeft: '2%'
        fontWeight: '600',
        padding: '1em',
        paddingBottom: '0',
        //maxWidth: '15em',
        minWidth: '425px',
        maxWidth: '425px',
        marginTop: '1em',
    },
    marginStyles: (y) => ({
        // width: '200%',
        paddingTop: `${10 + y / 20}em`,
        maxWidth: '75px', //'17em', //'150px',
        minWidth: '75px', //'15em',
        fontSize: '10pt',
        overflowX: 'hidden',
    }),
    leftMargin: {
        // paddingRight: '4%',
        marginTop: '4em',
        textAlign: 'right',
        maxWidth: '75px',
        minWidth: '75px',
        //marginLeft: '2em',
        //paddingLeft: '5em',
    },
    rightMargin: {
        // paddingLeft: '9%',
        marginTop: '4em',
        textAlign: 'left',
        //marginRight: '2em',
        //paddingRIght: '5em',
    },
    image: { maxWidth: '350px', fontSize: '10pt' },
};
