import { PUBLISHED_PAGE_UNLOADED, PUBLISHED_PAGE_LOADED, ARTICLE_UNPUBLISHED } from '../actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case PUBLISHED_PAGE_LOADED:
            return {
                ...state,
                articleSlug: action.payload ? action.payload.article.slug : window.location.pathname.split('/').pop(),
                title: action.payload ? action.payload.article.title : '',
                description: action.payload ? action.payload.article.description : '',
                body: action.payload ? action.payload.article.body : '',
                tagInput: '',
                tagList: action.payload ? action.payload.article.tagList : [],
                published: action.payload ? action.payload.article.published : false,
                authorUsername: action.payload ? action.payload.article.author.username : '',
                updatedAt: action.payload ? action.payload.article.updatedAt : '',
            };
        case ARTICLE_UNPUBLISHED:
        case PUBLISHED_PAGE_UNLOADED:
            return {};
    }

    return state;
};
