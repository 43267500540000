import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';

import { promiseMiddleware, localStorageMiddleware } from './middleware';
import reducer from './reducer';

// Made this change because of Chrome browser warning
// import createHistory from 'history/createBrowserHistory';
// eslint-disable-next-line no-undef
const createBrowserHistory = require('history').createBrowserHistory;

export const history = createBrowserHistory();

const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(routerMiddleware(history), promiseMiddleware, localStorageMiddleware);
    }
    // Enable additional logging in non-production environments.
    return applyMiddleware(routerMiddleware(history), promiseMiddleware, localStorageMiddleware, createLogger());
};

export const store = createStore(reducer(history), composeWithDevTools(getMiddleware()));
