export const PUBLISHED_TAG = 'published';
export const DELETED_TAG = 'deleted';

export const MOBILE_WIDTH = 750;
export const TABLET_WIDTH = 1025;

export const DATE_DISPLAY_OPTIONS = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

export const BABEL_DOMAINS = ['www.babelwriting.com', 'babelwriting.com', 'localhost', 'lucid-noether-f36b4c.netlify.app', '192.168.2.12'];

export const TAWK_TO_SCRIPT = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                            (function(){
                            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                            s1.async=true;
                            s1.src='https://embed.tawk.to/5ffb90e2c31c9117cb6d4382/1ernartdt';
                            s1.charset='UTF-8';
                            s1.setAttribute('crossorigin','*');
                            s0.parentNode.insertBefore(s1,s0);
                            })();`