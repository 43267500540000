import React, { useState } from "react";
import axios from "axios";
import Helmet from 'react-helmet';

import { TAWK_TO_SCRIPT } from '../constants';
import AppFooter from './Footer';

function Contact() {
  /* NEW: Input state handling vvvv */
  const [inputs, setInputs] = useState({
    email: "",
    message: ""
  });
  const handleOnChange = event => {
    event.persist();
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
  };
  /* End input state handling ^^^^ */

  // Server state handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      setInputs({
        email: "",
        message: ""
      });
    }
  };
  const handleOnSubmit = event => {
    event.preventDefault();
    setServerState({ submitting: true });
    axios({
      method: "POST",
      url: "https://formspree.io/f/mdopydba",
      data: inputs
    })
      .then(r => {
        handleServerResponse(true, "Thanks!");
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error);
      });
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2em', marginBottom: '18em'}}>
        <Helmet
            script={[{
                type: 'text/javascript',
                innerHTML: TAWK_TO_SCRIPT,
            }]} />
      <h1>Contact Us</h1>
      <div style={{marginTop: '1em', padding: '1em'}}>
          <p>We'd love to hear from you and learn what you think about Babel. Please contact us anytime.</p>
      </div>
      <div>
        <form onSubmit={handleOnSubmit} style={{display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left'}}>
            <label htmlFor="email">Email:</label>
            <input
                id="email"
                type="email"
                name="email"
                required
                onChange={handleOnChange}
                value={inputs.email}
            />
            <label htmlFor="message" style={{marginTop: '1em'}}>Message:</label>
            <textarea
                id="message"
                name="message"
                rows="8"
                cols="40"
                onChange={handleOnChange}
                value={inputs.message}
            />
            <button type="submit" disabled={serverState.submitting} style={{ height: '3em', fontSize: '13pt', marginTop: '1em', border: '0',  color: '#fff', backgroundColor: '#FF8C00', borderRadius: '2rem', fontSize: '1rem', marginLeft: '1em' }}>
                Submit
            </button>
            {serverState.status && (
            <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
            </p>
            )}
        </form>
      </div>
      <AppFooter />
    </div>
  );
};

export default Contact;