import { OPEN_MODAL, CLOSE_MODAL, OPEN_CANCEL_MODAL, CLOSE_CANCEL_MODAL } from '../actionTypes';

const defaultState = {
    isActive: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                isActive: true,
            };
        case CLOSE_MODAL:
            return {
                isActive: false,
            };
        case OPEN_CANCEL_MODAL:
            return {
                cancelModalIsActive: true,
            };
        case CLOSE_CANCEL_MODAL:
            return {
                cancelModalIsActive: false,
            };
        default:
            return state;
    }
};
