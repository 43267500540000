import React from 'react';
import { Link } from 'react-router-dom';
import agent from '../agent';

const styles = {
    plan: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
    },
    price: {
        fontSize: '24pt',
    },
};

function RegisterModal(props) {
    return (
        <div>
            <div className="actions">
                <button className="toggle-button" onClick={props.onClose}>
                    close
                </button>
            </div>
            <div className="premium-plan" style={styles.plan}>
                <div className="discount">special launch price of $3.99/month</div>
                <div
                    className="price"
                    style={{
                        fontSize: '24pt',
                        textDecorationLine: 'line-through',
                        textDecorationColor: '#5485bb',
                    }}
                >
                    $12.99 / month
                </div>
                <div className="benefits">
                    <ul id="1">Write and preview and publishd unlimited work</ul>
                    <ul id="2">Read unlimited writing</ul>
                    <ul id="3">Free forever</ul>
                </div>
                <div className="action">
                    <Link to="/checkout">
                        <button
                            className="btn btn-lg pull-xs-right btn-primary"
                            type="button"
                            style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                            onClick={props.onClose}
                            disabled={props.inProgress}
                        >
                            Upgrade
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

// Needs to have two states. Do you want to cancel? and cancelled./
function CancelModal(props) {
    // Need to handle this on the backend.
    // Maybe reach out to get user feedback from them.
    function onCancelSubscription() {
        props.onSubmitModal(agent.Payment.cancel(props.user.id));
    }

    return (
        <div>
            <div className="actions">
                <button className="toggle-button" onClick={props.onClose}>
                    close
                </button>
            </div>
            <div className="premium-plan" style={styles.plan}>
                <div className="discount">Are you sure you want to cancel?</div>
                <div
                    className="price"
                    style={{
                        fontSize: '24pt',
                        textDecorationLine: 'line-through',
                        textDecorationColor: '#5485bb',
                    }}
                >
                    $12.99 / month
                </div>
                <div className="benefits">
                    <ul id="1">Published stories will remain published</ul>
                    <ul id="2">You can reactivate any time for as low as $12.99 / month</ul>
                    <ul id="3">Continue to use the app or our free tier for as long as you like</ul>
                </div>
                <div className="action">
                    <button
                        className="btn btn-lg pull-xs-right btn-primary"
                        type="button"
                        style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                        onClick={onCancelSubscription}
                        disabled={props.inProgress}
                    >
                        {props.user.subscription ? 'Cancel' : 'Cancelled'}
                    </button>
                </div>
            </div>
        </div>
    );
}

function SignUpModal(props) {

    return (
        <div>
            <div className="actions">
                <button className="toggle-button" onClick={props.onClose} style={{backgroundColor: '#5485bb', color: '#fff'}}>
                    close
                </button>
            </div>
            <div className="premium-plan" style={styles.plan}>
                {/* <div className="discount">Sign up to publish your work</div> */}
                <h2>Sign up to publish your work</h2>
                <div className="benefits" style={{padding: '2em'}}>
                    <ul id="1">Write, Preview, and Publish for free.</ul>
                    <ul id="2">Premium features available for purchase.</ul>
                    <ul id="3">Our focus is making your writing beautiful.</ul>
                </div>
                <div className="action">
                    <a href="/pricing" target="_blank">
                        <button
                            className="btn btn-lg pull-xs-right btn-primary"
                            type="button"
                            style={{ borderRadius: '2rem', fontSize: '1rem', marginRight: '5px' }}
                            disabled={props.inProgress}
                        >
                            {'Sign up'}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

function CommentModal(props) {

    return (
        <div>
            <div className="actions">
                <button className="toggle-button" onClick={props.onClose} style={{backgroundColor: '#5485bb', color: '#fff'}}>
                    close
                </button>
            </div>
            <div className="premium-plan" style={styles.plan}>
                <p dangerouslySetInnerHTML={{ __html: props.content }}></p>

            </div>
        </div>
    );
}

function ModalMap(props) {
    if (props.modalType === 'register') {
        return <RegisterModal {...props} />
    } else if (props.modalType === 'cancel') {
        return <CancelModal {...props} />
    } else if (props.modalType === 'signup') {
        return <SignUpModal {...props} />
    } else if (props.modalType === 'comment') {
        return <CommentModal {...props} />
    }
}

export default class Modal extends React.Component {
    render() {
        if (!this.props.isActive) {
            return null;
        }
        return (
            <div
                className="modal-overlay"
                style={{
                    zIndex: '1000',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.5)',
                    opacity: '1',
                    zIndex: 999,
                }}
            >
                <div className="modal" id="modal" style={{width: '80%'}}>
                    <ModalMap {...this.props} />
                    {/* {this.props.register ? <RegisterModal {...this.props} /> : <CancelModal {...this.props} />} */}
                </div>
            </div>
        );
    }
}
