/*
React App that takes pricing / billing codes from JS Object (potentiall an external file) and creates pricing cards with toggle to switch between product lineups

Button links, billing codes, pricing, descriptions, etc. all change on toggle

*/
import React from 'react';
import { Helmet } from 'react-helmet';

import { TAWK_TO_SCRIPT } from '../constants';
import { isMobile } from './if-mobile';
import AppFooter from './Footer';


const pricingDataAll = {
    generic: {
        priceDesc: 'billed monthly',
        ctaText: 'Select',
        ctaLinkPrefix: 'http://www.google.com/',
        ctaSecondaryText: 'purchase now',
        ctaSecondaryLinkPrefix: 'http://www.bing.com/',
        description: "This is the most basic package but it's also the cheapest. Great for ordinary use.",
    },

    common: {
        one: {
            description: ['Write, Preview, and Publish', 'Zero pressure to upgrade'],
        },
        two: {
            description: [
                'Write, Preview, and Publish',
                'Bring your own domain',
                'Set canonical URLs',
                'Build an audience with emailCollect',
            ],
        },
        three: {
            description:
                "Enterprise edition. Heavy duty awesomeness that'll handle just about anything you can throw at it, including the kitchen sink.",
        },
    },

    regular: {
        generic: {
            priceOverview: 'Standard Version',
        },

        one: {
            title: 'Basic',
            price: 'Free',
            billingCode: 'basic-regular',
            url: '/registration',
            priceOverview: 'Get started with Babel',
            free: true,
        },
        two: {
            title: 'Premium',
            price: 12.99,
            billingCode: 'advanced-regular',
            url: '/purchase',
            priceOverview: 'Write beautifully, build an audience',
        },
        three: {
            title: 'Enterprise',
            price: 2.99,
            billingCode: 'enterprise-regular',
            priceOverview: 'Standard Version',
        },
    },

    promo: {
        generic: {
            priceOverview: '',
        },
        one: {
            title: 'Basic',
            price: 'Free',
            billingCode: 'basic-extra',
            url: '/registration',
            priceOverview: 'Get started with Babel',
            free: true,
        },
        two: {
            title: 'Discount Premium',
            price: 8.99,
            billingCode: 'advanced-extra',
            url: '/purchase',
            priceOverview: 'Write beautifully, build an audience',
        },
        three: {
            title: 'Enterprise with Extra',
            price: 3.45,
            billingCode: 'enterprise-extra',
            priceOverview: 'Includes all Extra features',
        },
    },
};

class PricingCardContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            promo: false,
        };
    }

    togglePromo() {
        this.setState({
            promo: !this.state.promo,
        });
    }

    render() {
        let pricingDataCurrent = this.props.pricingDataAll.regular;
        let common = this.props.pricingDataAll.common;

        this.state.promo
            ? (pricingDataCurrent = this.props.pricingDataAll.promo)
            : (pricingDataCurrent = this.props.pricingDataAll.regular);

        const generic = this.props.pricingDataAll.generic,
            one = pricingDataCurrent.one,
            two = pricingDataCurrent.two,
            three = pricingDataCurrent.three;

        return (
            <div className="container page" style={{marginBottom: '18em'}}>
                <h1>Select Your Package</h1>
                {/* <div className="toggle-container" style={{ padding: '1em', display: 'flex', justifyContent: 'center', alignItems: 'center', alignItems: 'baseline' }}>
                    <h5>🎉&nbsp;&nbsp;Apply Your Discount</h5> <input type="checkbox" onChange={this.togglePromo.bind(this)} style={{marginLeft: '1em'}}></input>
                </div> */}
                <div style={{ textAlign: 'center', border: '2px solid grey', margin: '1em', padding: '1em'}}>
                    <i>I've never felt so satisfied with my writing as when I use Babel</i><br />- Sean L (Premium user)
                </div>

                <div className={isMobile() ? 'containerPricingMobile' : 'containerPricing'}>
                    <PricingCard pricingData={one} common={common.one} generic={generic}></PricingCard>

                    <PricingCard pricingData={two} common={common.two} generic={generic} featured></PricingCard>
                </div>
            </div>
        );
    }
}

class PricingCard extends React.Component {
    render() {
        const price = this.props.pricingData.price.toString().split('.'),
            dollar = price[0],
            free = this.props.pricingData.free,
            title = this.props.pricingData.title;

        let cent = price[1] || '00';

        return (
            <div className={'pricingCard ' + (this.props.featured ? 'featured' : '')}>
                <div className="title">{this.props.pricingData.title}</div>

                <div className="card">
                    {title !== 'Discount Premium' ? <h3>&nbsp;</h3> : <h3>Save 30%</h3>}
                    {free ? (
                        <h2 className="price">
                            <span className="price__currency">Free</span>
                        </h2>
                    ) : (
                        <h2 className="price">
                            <span className="price__currency">$</span>
                            <span className="price__dollar">{dollar}</span>.<span className="price__cent">{cent}</span>
                            <span className="price__cent">/mo</span>
                        </h2>
                    )}

                    {free ? (
                        <p className="price-desc">&nbsp;</p>
                    ) : (
                        <p className="price-desc">{this.props.generic.priceDesc}</p>
                    )}

                    <p className="price-overview">{this.props.pricingData.priceOverview}</p>

                    <div
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flexStart', textAlign: 'left' }}
                    >
                        <ul>
                            {this.props.common.description.map((item, index) => {
                                return (
                                    <li key={index} className="description">
                                        {item}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <a
                    className={'bttn bttn-' + this.props.btnClass}
                    href={this.props.pricingData.url}
                >
                    {this.props.generic.ctaText}
                </a>
            </div>
        );
    }
}

export default class PricingPage extends React.Component {
    render() {
        return (<div>
            <Helmet
                script={[{
                    type: 'text/javascript',
                    innerHTML: TAWK_TO_SCRIPT,
                }]} />
                <PricingCardContainer pricingDataAll={pricingDataAll} />
                <AppFooter />
            </div>)
    }
}
