import React from 'react';
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';

function footerCode() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '0'}}>
            <a
                href={`http://${window.location.host}.com/`}
            >   About
            </a> 
            <a
                href={`http://${window.location.host}/blog`}
            >   Blog
            </a>
            <a
                href={`http://${window.location.host}/pricing`}
            >   Pricing
            </a>
            <a
                href={`http://${window.location.host}/contact`}
            >   Contact
            </a> 
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/BabelWriting"
            >
                <img
                    src="https://uploads.divjoy.com/icon-twitter.svg"
                    alt="Twitter"
                />
                &nbsp;Twitter
            </a>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                <div><p>Made with ❤️&nbsp;in Canada</p></div>
                {/* <div style={{display:' flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <a href="/tos">Terms of Service  &nbsp;|&nbsp;</a>
                    <a href="/privacy">&nbsp;Privacy</a>
                </div> */}
            </div>
        </div>
    )
}

export default function AppFooter() {
    return <Footer
                columns={[]}
                theme='light'
                bottom={footerCode()}
                style={{position: 'absolute', marginTop: '16em', padding: '0'}}
            />
}