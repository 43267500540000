/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import DanteEditor from 'Dante2';
import DOMPurify from 'dompurify';

import agent from '../agent';
import ListErrors from './ListErrors';
import { ReactComponent as ForwardArrow } from './Assets/forward-arrow.svg';
import { isMobile } from './if-mobile';
import AppFooter from './Footer';

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

async function uploadImagesAndFixUrls(originalBlocks, html, slug) {
    let fixedHtml = `${html}`;
    const blocks = Object.assign([], originalBlocks.blocks);

    for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        if (block.type !== 'image') {
            continue;
        }

        const { url } = block.data;

        if (!url.startsWith('blob:')) {
            continue;
        }

        // eslint-disable-next-line no-await-in-loop
        const blob = await fetch(url).then((r) => r.blob());

        const uploadFormData = new FormData();
        uploadFormData.append('file', blob);

        // eslint-disable-next-line no-await-in-loop
        const uploadRes = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/uploads?src=editor&articleID=${slug}`, {
            method: 'POST',
            body: uploadFormData,
        });

        // eslint-disable-next-line default-case
        switch (uploadRes.status) {
            case 200: // OK
                const { url: uploadedUrl } = await uploadRes.json();
                // blocks[i].data.url = uploadedUrl;
                fixedHtml = fixedHtml.replace(url, uploadedUrl);
                break;
            case 500: // INTERNAL_SERVER_ERROR
                throw new Error();
        }
    }

    return { data: fixedHtml };
}

export function Editorx(props) {

    //const dispatch = useDispatch()

    const {
        articleSlug,
        title,
        description,
        tagInput,
        tagList,
        published,
        inProgress,
        editorState,
        onLoad,
        onUpdateField,
        onAddTag,
        onRemoveTag,
        onUnload,
        onSubmit,
        match,
        errors,
        body,
    } = props;

    //const [localEditorState, setLocalEditorState] = useState(editorState);
    //const [localBody, setLocalBody] = useState(body);
    
    // This isn't really state. As in nothing looks different
    let dirty = false;
    let localBody = '';

    const updateFieldEvent = (key) => (ev) => onUpdateField(key, ev.target.value);
    const changeTitle = updateFieldEvent('title');
    const changeDescription = updateFieldEvent('description');
    const changeBody = updateFieldEvent('body');
    const changeTagInput = updateFieldEvent('tagInput');

    useEffect(() => {
        if (match.params.slug) {
          return onLoad(agent.Articles.get(match.params.slug));
        }
        // Create an article
        const article = {
            title: 'Untitled Article',
            description: '',
            body: '',
            tagList: [],
            published: false,
        };
        onLoad(agent.Articles.create(article));
        return () => onUnload();
    }, [match.params.slug]);

    useEffect(() => {
        if (body) {
            localBody = body; 
        }
    }, [body])

    const watchForEnter = (ev) => {
        if (ev.keyCode === 13) {
            ev.preventDefault();
            onAddTag();
        }
    };

    const removeTagHandler = (tag) => () => {
        onRemoveTag(tag);
    };

    const submitForm = async (ev) => {
        ev.preventDefault();

        let tempEditorState;
        if (dirty && Object.keys(editorState).length > 0) {
            tempEditorState = JSON.stringify(editorState)
        } else {
            tempEditorState = editorState;
        }

        // upload images
        const resp = await uploadImagesAndFixUrls(JSON.parse(tempEditorState), tempEditorState);
        const fixedEditorState = resp.data;

        const article = {
            title: title,
            description: description,
            body: fixedEditorState,
            tagList: tagList,
            published: published,
        };

        const slug = { slug: articleSlug };

        const promise =
            articleSlug
                ? agent.Articles.update(Object.assign(article, slug))
                : agent.Articles.create(article);

        onSubmit(promise);
    };

    const saveContents = debounce(async (editor) => {
        if (dirty) {
            const tempEditorState = editor.emitSerializedOutput()
            const resp = await uploadImagesAndFixUrls(JSON.parse(tempEditorState), JSON.stringify(tempEditorState));

            const fixedEditorState = resp.data;            
            const article = {
                title: title,
                description: description,
                body: fixedEditorState, 
                published: published,
            };
            // this.props.editorSlug || 
            const slug = { slug: this.props.articleSlug };

            articleSlug 
                ? await agent.Articles.update(Object.assign(article, slug))
                : await agent.Articles.create(article);

        } else {
            dirty = false;
        }
    }, 500);

    return (
        <div className="editor-page">
                <div className="container page" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <div className="preview-btn">
                        <button
                            type="button"
                            onClick={submitForm}
                            style={{ backgroundColor: '#ffffff', border: '0' }}
                            title="Preview your work"
                        >
                            <ForwardArrow />
                        </button>
                    </div>

                    <div className="row" style={{ width: '100%', width: '-webkit-fill-available', width: '-moz-available'  }}>
                        <div
                            className="col-md-10 offset-md-1 col-xs-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '-webkit-fill-available', width: '-moz-available'  
                            }}
                        >
                            <ListErrors errors={errors} />

                            <form style={{ maxWidth: '740px', width: '100%',}}>
                                <fieldset>
                                    <fieldset className="form-group">
                                        <input
                                            className="form-control form-control-lg editor-form"
                                            style={{
                                                border: '0px',
                                                padding: '0px',
                                                fontFamily:
                                                    '"Calendas Plus", "Crimson Text", "Hoefler Text", "Georgia", serif',
                                                fontSize: '24pt',
                                            }}
                                            type="text"
                                            placeholder="Title ..."
                                            value={title}
                                            onChange={changeTitle}
                                        />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        {localBody ? (
                                            <DanteEditor
                                                read_only={false}
                                                content={localBody}
                                                default_wrappers={[{ className: 'my-custom-p', block: 'unstyled' }]}
                                                onChange={(editor) => saveContents(editor)}
                                            />
                                        ) : (
                                            <DanteEditor
                                                read_only={false}
                                                content={localBody || Lorem}
                                                default_wrappers={[{ className: 'my-custom-p', block: 'unstyled' }]}
                                                onChange={(editor) => saveContents(editor)}
                                            />
                                        )}
                                    </fieldset>

                                    <fieldset className="form-group">
                                        <input
                                            className="form-control"
                                            style={{ border: '0px', padding: '0px' }}
                                            type="text"
                                            placeholder="Enter tags"
                                            value={tagInput}
                                            onChange={changeTagInput}
                                            onKeyUp={watchForEnter}
                                        />

                                        <div className="tag-list">
                                            {(tagList || []).map((tag) => (
                                                <span className="tag-default tag-pill" key={tag}>
                                                    <i
                                                        role="button"
                                                        label="tags"
                                                        className="ion-close-round"
                                                        onClick={removeTagHandler(tag)}
                                                    />
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    </fieldset>
                                    <button
                                        className="btn btn-lg pull-xs-right btn-primary"
                                        type="button"
                                        style={{ borderRadius: '2rem', fontSize: '1rem' }}
                                        disabled={inProgress}
                                        onClick={submitForm}
                                    >
                                        Preview work
                                    </button>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <AppFooter />
            </div>
        );
}

export default class Editor extends React.Component {
    constructor() {
        super();

        const updateFieldEvent = (key) => (ev) => this.props.onUpdateField(key, ev.target.value);
        this.changeTitle = updateFieldEvent('title');
        this.changeDescription = updateFieldEvent('description');
        this.changeBody = updateFieldEvent('body');
        this.changeTagInput = updateFieldEvent('tagInput');
        this.dirty = false;
        this.commentaryBlock = () => ({
            key: Math.floor(Math.random() * 10000).toString(),
            text: '==============================================',
            type: 'commentaryMarker',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        });
        this.state = {
            commentaryUpdated: false,
        };

        this.editorState = {};

        this.watchForEnter = (ev) => {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.props.onAddTag();
            }
        };

        this.removeTagHandler = (tag) => () => {
            this.props.onRemoveTag(tag);
        };

        this.blockOffContentForCommentary = (content) => {
            const parsedContent = JSON.parse(content);
            return {
                ...parsedContent,
                blocks: parsedContent.blocks
                    .map((e, i) =>
                        i > 0 && i < parsedContent.blocks.length
                            ? [this.commentaryBlock(), e, this.commentaryBlock()]
                            : [e],
                    )
                    .reduce((a, b) => a.concat(b)),
            };
        };

        this.stripOutCommentaryMarks = (content) => ({
            ...content,
            blocks: content.blocks.filter((block) => block.type !== 'commentaryMarker'),
        });

        this.submitForm = async (ev) => {
            ev.preventDefault();

            let editorState;
            if (this.props.commentary) {
                editorState = this.stripOutCommentaryMarks(this.editorState)
            } else if (this.dirty && Object.keys(this.editorState).length > 0) {
                editorState = JSON.stringify(this.editorState)
            } else {
                editorState = this.props.editorState;
            }

            let fixedEditorState;
            if (!this.props.commentary) {
                // upload images
                const resp = await uploadImagesAndFixUrls(JSON.parse(editorState), editorState, this.props.articleSlug);

                fixedEditorState = resp.data;
            } else {
                fixedEditorState = JSON.stringify(editorState)
            }

            const article = {
                title: this.props.title,
                description: this.props.description,
                body: fixedEditorState, //DOMPurify.sanitize(fixedEditorState),,
                tagList: this.props.tagList,
                published: this.props.published,
            };

            const slug = { slug: this.props.articleSlug };

            const promise =
                this.props.articleSlug && !this.props.commentary
                    ? agent.Articles.update(Object.assign(article, slug))
                    : agent.Articles.create(article);

            this.props.onSubmit(promise);
        };

        this.duplicateText = (ev) => {
            ev.preventDefault();
            const editorState = this.props.commentary
                ? this.stripOutCommentaryMarks(this.editorState)
                : this.props.editorState;

            const article = {
                title: editorState.blocks && editorState.blocks[0].text,
                description: this.props.description,
                body: JSON.stringify(editorState),
                tagList: this.props.tagList,
            };
            const promise = agent.Articles.create(article);
            this.props.onSubmit(promise);
        };

        this.saveContents = debounce(async (editor) => {
            if (this.dirty) {

                // We don't want it save if the user is just clicking around.
                const newEditorState = editor.emitSerializedOutput();
                const stringNewEditorState = JSON.stringify(newEditorState);
                if (stringNewEditorState === JSON.stringify(this.editorState)) {
                    return;
                }
                this.editorState = newEditorState; //editor.emitSerializedOutput();
                const editorState = stringNewEditorState; //JSON.stringify(this.editorState)

                let resp;
                if (this.props.articleSlug) {
                    resp = await uploadImagesAndFixUrls(JSON.parse(editorState), editorState, this.props.articleSlug);
                }

                const fixedEditorState = resp ? resp.data : editorState;            
                const article = {
                    title: this.props.title,
                    description: this.props.description,
                    body: fixedEditorState, //DOMPurify.sanitize(fixedEditorState),
                    tagList: this.props.tagList,
                    published: this.props.published,
                };
                const slug = { slug: this.props.articleSlug };
                this.props.articleSlug && !this.props.commentary
                    ? await agent.Articles.update(Object.assign(article, slug))
                    : await agent.Articles.create(article);

            } else {
                this.dirty = true;
            }
        }, 1000);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.body && !this.body) {
            this.props.commentary && !this.state.commentaryUpdated
                ? (this.body = this.blockOffContentForCommentary(nextProps.body))
                : (this.body = JSON.parse(nextProps.body));
        }

        // This is for commentary
        if (this.props.match.params.slug !== nextProps.match.params.slug) {
            if (nextProps.match.params.slug) {
                this.props.onUnload();
                return this.props.onLoad(agent.Articles.get(this.props.match.params.slug));
            }
            this.props.onLoad(null);
        }
    }

    componentWillMount() {
        this.dirty = false;
        if (this.props.match.params.slug) {
            return this.props.onLoad(agent.Articles.get(this.props.match.params.slug));
        }
        // Create an article
        const article = {
            title: 'Untitled Article',
            description: '',
            body: '',
            tagList: [],
            published: false,
        };
        return this.props.onLoad(agent.Articles.create(article));
        //this.props.onLoad(null);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (isMobile()) {
            const styles = `
                .inlineToolTip { 
                    left: 0 !important;
                    padding-left: 20px !important;
                }`;

            const styleSheet = document.createElement('style');
            styleSheet.type = 'text/css';
            styleSheet.innerText = styles;
            document.head.appendChild(styleSheet);
        }
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    render() {
        return (
            <div className="editor-page" style={{marginTop: '4em'}}>
                <div className="container page" style={{ display: 'flex', flexDirection: 'row-reverse', minHeight: '150vh', marginBottom: '20em' }}>
                    <div className="preview-btn">
                        <button
                            type="button"
                            onClick={this.submitForm}
                            style={{ backgroundColor: '#ffffff', border: '0' }}
                            title="Preview your work"
                        >
                            <ForwardArrow />
                        </button>

                        {/* <button
                            className="btn btn-lg pull-xs-right btn-primary"
                            style={{position: 'fixed', borderRadius: '50%', backgroundColor: '#fff', borderColor: '#00bbbb', borderWidth: 'medium'}}
                            onClick={this.submitForm}
                            type="button"
                            ></button> */}
                        {/* artefact there was a button here that made a new document that was a copy of what you were writing */}
                    </div>

                    <div className="row" style={{ width: '100%' }}>
                        <div
                            className="col-md-10 offset-md-1 col-xs-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <ListErrors errors={this.props.errors} />

                            <form style={{ maxWidth: '740px', width: '100%' }}>
                                <fieldset>
                                    <fieldset className="form-group">
                                        <input
                                            className="form-control form-control-lg editor-form"
                                            style={{
                                                border: '0px',
                                                padding: '0px',
                                                fontFamily:
                                                    '"Calendas Plus", "Crimson Text", "Hoefler Text", "Georgia", serif',
                                                fontSize: '24pt',
                                            }}
                                            type="text"
                                            placeholder="Title ..."
                                            value={this.props.title}
                                            onChange={this.changeTitle}
                                        />
                                    </fieldset>
                                    <fieldset className="form-group">
                                        {this.body ? (
                                            <DanteEditor
                                                read_only={false}
                                                content={this.body}
                                                default_wrappers={[
                                                    { className: 'my-custom-p', block: 'unstyled' },
                                                    { className: 'my-custom-p', block: 'unordered-list-item' },
                                                    { className: 'my-custom-p', block: 'ordered-list-item' },
                                                ]}
                                                // onChange={(editor) => {
                                                //     this.editorState = editor.emitSerializedOutput();
                                                // }}
                                                onChange={(editor) => this.saveContents(editor)}
                                            />
                                        ) : (
                                            <DanteEditor
                                                read_only={false}
                                                content={this.body || Lorem}
                                                default_wrappers={[
                                                    { className: 'my-custom-p', block: 'unstyled' },
                                                    { className: 'my-custom-p', block: 'unordered-list-item' },
                                                    { className: 'my-custom-p', block: 'ordered-list-item' },
                                                ]}                                                // onChange={(editor) => {
                                                //     console.log('here');
                                                //     if (this.dirty) {
                                                //         this.editorState = editor.emitSerializedOutput();
                                                //     } else {
                                                //         this.dirty = true;
                                                //     }
                                                // }}
                                                onChange={(editor) => this.saveContents(editor)}
                                            />
                                        )}
                                    </fieldset>

                                    <fieldset className="form-group">
                                        <input
                                            className="form-control"
                                            style={{ border: '0px', padding: '0px' }}
                                            type="text"
                                            placeholder="Enter tags"
                                            value={this.props.tagInput}
                                            onChange={this.changeTagInput}
                                            onKeyUp={this.watchForEnter}
                                        />

                                        <div className="tag-list">
                                            {(this.props.tagList || []).map((tag) => (
                                                <span className="tag-default tag-pill" key={tag}>
                                                    <i
                                                        role="button"
                                                        label="tags"
                                                        className="ion-close-round"
                                                        onClick={this.removeTagHandler(tag)}
                                                    />
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    </fieldset>
                                    <button
                                        className="btn btn-lg pull-xs-right btn-primary"
                                        type="button"
                                        style={{ borderRadius: '2rem', fontSize: '1rem' }}
                                        disabled={this.props.inProgress}
                                        onClick={this.submitForm}
                                    >
                                        Preview work
                                    </button>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>  
                <AppFooter />
            </div>
        );
    }
}

const Lorem = {
    blocks: [
        {
            key: '99c5e',
            text: 'Tell your story ...',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
    ],
    entityMap: {},
};
