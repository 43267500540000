import {
    ARTICLE_FAVORITED,
    ARTICLE_UNFAVORITED,
    SET_PAGE,
    APPLY_TAG_FILTER,
    HOME_PAGE_LOADED,
    HOME_PAGE_UNLOADED,
    CHANGE_TAB,
    PROFILE_PAGE_LOADED,
    PROFILE_PAGE_UNLOADED,
    PROFILE_FAVORITES_PAGE_LOADED,
    PROFILE_FAVORITES_PAGE_UNLOADED,
    ARTICLE_DELETED,
} from '../actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_PAGE:
            return {
                ...state,
                articles: action.payload.articles,
                articlesCount: action.payload.articlesCount,
                currentPage: action.page,
            };
        case APPLY_TAG_FILTER:
            return {
                ...state,
                pager: action.pager,
                articles: action.payload.articles,
                articlesCount: action.payload.articlesCount,
                tab: null,
                tag: action.tag,
                currentPage: 0,
            };
        case HOME_PAGE_LOADED:
            return {
                ...state,
                pager: action.pager,
                tags: action.payload[0] && action.payload[0].tags,
                articles: action.payload[1] && action.payload[1].articles,
                articlesCount: action.payload[1] && action.payload[1].articlesCount,
                currentPage: 0,
                tab: action.tab,
            };
        case HOME_PAGE_UNLOADED:
            return {};
        case CHANGE_TAB:
            return {
                ...state,
                pager: action.pager,
                articles: action.payload.articles,
                articlesCount: action.payload.articlesCount,
                tab: action.tab,
                currentPage: 0,
                tag: null,
            };
        case PROFILE_PAGE_LOADED:
        case PROFILE_FAVORITES_PAGE_LOADED:
            return {
                ...state,
                pager: action.pager,
                articles: action.payload[1].articles,
                articlesCount: action.payload[1].articlesCount,
                currentPage: 0,
            };
        case PROFILE_PAGE_UNLOADED:
        case PROFILE_FAVORITES_PAGE_UNLOADED:
            return {};
        case ARTICLE_DELETED:
            return {
                ...state,
                articles: state.articles.filter((article) => article.slug !== action.payload.article.slug),
            };
        default:
            return state;
    }
};
