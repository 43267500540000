import React from 'react';

import { DELETED_TAG } from '../constants';
import ArticlePreview from './ArticlePreview';
import ListPagination from './ListPagination';

const ArticleList = (props) => {
    const isProfile = props.profile;

    if (!props.articles) {
        return <div className="article-preview">Loading...</div>;
    }

    if (props.articles.length === 0) {
        return (
            <div className="article-preview">
                {props.tab === 'your-articles'
                    ? 'What are you waiting for ... get writing'
                    : 'Annotations coming soon ...'}
            </div>
        );
    }

    // Move filtering to server if possible
    return (
        <div>
            {props.articles
                .filter((article) => !article.tagList.includes(DELETED_TAG))
                .map((article) => (
                    <ArticlePreview article={article} key={article.slug} profile={isProfile} />
                ))}

            <ListPagination pager={props.pager} articlesCount={props.articlesCount} currentPage={props.currentPage} />
        </div>
    );
};

export default ArticleList;
